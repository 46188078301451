import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { AuthService } from '../../boilerplate/auth.service';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { IdleTimeoutManager } from "idle-timer-manager";
import { UrlService } from '../url.service';
import { Observable } from 'rxjs';




@Component({
  selector: 'app-autologoff',
  templateUrl: './autologoff.component.html',
  styleUrls: ['./autologoff.component.css']
})
export class AutologoffComponent implements OnInit {
  config: CountdownConfig = {
    leftTime: 30,
    formatDate: ({ date }) => `${date / 1000}`,
  };
  localUserData: LocalUser;;
  timer: any;
  previousUrl: Observable<string> = this.urlService.previousUrl$;


  constructor(
    public router: Router,
    public authservice: AuthService,
    private urlService: UrlService,
  ) {
    
    //console.log(Date() ,'constructor')


  }

  ngOnInit(): void {
    //console.log('init autologoff')
    if (localStorage.getItem('userData') !== null ) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
     
     
    }
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      //console.log(previousUrl)
      if(previousUrl=="/sign-in"||previousUrl=="/sign-up"||previousUrl=="/"||previousUrl=="/welcome"||previousUrl=="/welcome"){

        //this.router.navigate([previousUrl])
      }
    
     }); 



  }


  handleEvent(e: CountdownEvent) {
    //console.log(Date() ,'Actions', e);
    if (e.action == 'done') {

     
      this.authservice.SignOut()
      this.router.navigate(['sign-in']);
    }
  }
  onTravcolsList() {

   this.router.navigate(['travcols-list'])
   this.timer = new IdleTimeoutManager({
      
    timeout: 1200, // expired after 20 min
    onExpired: () => {
      //console.log(Date() ,'autologoff')
      this.router.navigate(['autologoff']);
    }
  });
    
  }
}
