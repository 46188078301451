import { NgModule } from '@angular/core';
import { Routes, RouterModule,CanActivate, RouterState  } from '@angular/router';

import { AuthGuard } from "./auth.guard";

import { SignInComponent } from './components/boilerplate/sign-in/sign-in.component';
import { SignUpComponent } from './components/boilerplate/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/boilerplate/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/boilerplate/verify-email/verify-email.component';
import { ProfileComponent} from './components/boilerplate/profile/profile.component';
import { AddTravColComponent } from './components/travcols/add-travcol/add-travcol.component';
import { TravColsListComponent } from './components/travcols/travcols-list/travcols-list.component';
import { EditTravColComponent } from './components/travcols/edit-travcol/edit-travcol.component';
import { WelcomeComponent } from './components/others/welcome/welcome.component';
import { ColleaguesListComponent } from './components/colleagues/colleagues-list/colleagues-list.component';
import { EditColleagueComponent } from './components/colleagues/edit-colleague/edit-colleague.component';
import { GreetingsComponent } from './components/others/greetings/greetings.component';
import { TravColsPrintComponent } from './components/travcols/travcols-print/travcols-print.component';
import { AddColleagueComponent } from './components/colleagues/add-colleague/add-colleague.component';
import { TravcolsToBeValidatedComponent } from './components/Travcols/travcols-to-be-validated/travcols-to-be-validated.component';
import { ParametersComponent } from './components/others/parameters/parameters.component';
import { InviteColleaguesComponent } from './components/colleagues/invite-colleagues/invite-colleagues.component';
import { TronchesComponent } from './components/others/tronches/tronches.component';
import { EditInfoComponent } from './components/others/edit-info/edit-info.component';
import { TronchesbinoscopeComponent } from './components/others/tronchesbinoscope/tronchesbinoscope.component';
import { AutologoffComponent } from './components/others/autologoff/autologoff.component';
import { SpamMessageComponent } from './components/boilerplate/spam-message/spam-message.component';
import { SubstituteComponent } from './components/others/substitute/substitute.component';
import { AllTravcolsComponent } from './components/travcols/all-travcols/all-travcols.component';
import { TermsOfUseComponent } from './components/others/terms-of-use/terms-of-use.component';






const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
//unprotected routes
  { path: 'welcome', component: WelcomeComponent},
  { path: 'sign-in', component: SignInComponent},
  { path: 'sign-up', component: SignUpComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'autologoff', component: AutologoffComponent},
  { path: 'spam-message', component: SpamMessageComponent},
  { path: 'terms-of-use', component: TermsOfUseComponent},

//logged in routes

// guest routes
{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] , data: {role: 1  } },
{ path: 'travcols-list', component: TravColsListComponent, canActivate: [AuthGuard] , data: {role: 1  }},
{ path: 'travcols-to-be-validated', component: TravcolsToBeValidatedComponent, canActivate: [AuthGuard], data: {role: 1  }},
{ path: 'greetings', component: GreetingsComponent, canActivate: [AuthGuard], data: {role: 1  }},

// user routes
  { path: 'colleagues-list', component: ColleaguesListComponent, canActivate: [AuthGuard], data: {role: 1.5  }},
  { path: 'add-travcol', component: AddTravColComponent, canActivate: [AuthGuard], data: {role: 1.5  } },
  { path: 'edit-travcol/:userEmailID/:ownerID/:key', component: EditTravColComponent, canActivate: [AuthGuard], data: {role: 1.5  } },
  { path: 'travcols-print', component: TravColsPrintComponent, canActivate: [AuthGuard], data: {role: 1.5  }},
  { path: 'invite-colleagues', component: InviteColleaguesComponent, canActivate: [AuthGuard], data: {role: 1.5  }},
  { path: 'edit-info', component: EditInfoComponent, canActivate: [AuthGuard], data: {role: 1.5  }},
 
// non blocked user routes  
  { path: 'tronches/:id', component: TronchesComponent, canActivate: [AuthGuard], data: {role: 2  }},
  { path: 'tronchesbinoscope', component: TronchesbinoscopeComponent, canActivate: [AuthGuard], data: {role: 2  }},

  //admin routes
  { path: 'edit-colleague/:id', component: EditColleagueComponent, canActivate: [AuthGuard] , data: {role: 3  }},
  { path: 'add-colleague', component: AddColleagueComponent, canActivate: [AuthGuard], data: {role: 3  } },
  { path: 'parameters', component: ParametersComponent, canActivate: [AuthGuard], data: {role: 3  }},

// superadmin routes
{ path: 'substitute', component: SubstituteComponent, canActivate: [AuthGuard], data: {role: 4  }},
{ path: 'all-travcols', component: AllTravcolsComponent, canActivate: [AuthGuard], data: {role: 4  }},


// put superadmin routes here

  //other
  
  { path: '**', redirectTo: 'welcome' },
  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { 

constructor(){ 
  // console.log(Date() ,'approuting constructor')
   
}



}