import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
//import { globalUserData } from 'src/app/app.component';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../boilerplate/local-user';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  debug: boolean = false
  emailID: string;
  favoritesListRef: AngularFireList<any>;
  localUserData: LocalUser;;

  constructor(
    private db: AngularFireDatabase,
    //public authservice: AuthService,
    public toastr: ToastrService, // Toastr service for alert message
  ) { // console.log(Date() ,'constructor')

    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)

    }
    this.emailID = this.localUserData.emailID
  }







  AddFavorite(choosenColleague) {
    if (this.debug) console.log(Date(), 'choosenColleague:', choosenColleague)
    var path = 'favorites/' + choosenColleague.userEmailID + '/' + choosenColleague.colleagueEmailID
    if (this.debug) console.log(Date(), path)
    var data = {
      userEmailID: choosenColleague.userEmailID,
      colleagueEmailID: choosenColleague.colleagueEmailID,
      fullName: choosenColleague.firstName + ' ' + choosenColleague.lastName
    }
    if (this.debug) console.log(Date(), data)
    this.db.database.ref().child(path).update(data)
      .catch(function (error) {
        console.log(Date(), "Add Favorite failed: " + error.message)
      });
  }


  DeleteFavorite(choosenColleague) {
    if (this.debug) console.log(Date(), 'choosenColleague:', choosenColleague)
    var path = 'favorites/' + choosenColleague.userEmailID + '/' + choosenColleague.colleagueEmailID
    if (this.debug) {
      // console.log(Date() ,path)
    }
    else {
      this.db.database.ref().child(path).remove()
        .then(function () {
          //console.log(Date() ,"Remove succeeded.")
        })
        .catch(function (error) {
          console.log(Date(), "Remove failed: " + error.message)
        });
    }
  }


  // Fetch Colleagues List
  GetFavoritesList(userEmailID) {
    //console.log(Date() ,userEmailID)
    this.favoritesListRef = this.db.list('favorites/' + userEmailID);
    return this.favoritesListRef;
  }


}
