<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class=" border-bottom" style="text-align: center; margin-bottom: -30px; margin-top: 100px;">
  <h1 *ngIf="colleague" style="color:white;">Tronche de {{colleague.firstName}} {{colleague.lastName}} </h1>
</div>

<div class="container mt-5 text-center">

</div>

<div class="wrapper">

  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">

        <div class="card_top center">



          <label *ngIf="(colleague)" style="margin:auto;" for="fileInput">
            <img class="img2" *ngIf="colleague.photoURL!='Einstein'" src={{colleague.photoURL}}>
            <img class="img2" *ngIf="colleague.photoURL=='Einstein'" src='./assets/images/profile2.png'>

          </label>



        </div>
        <div class="card_bottom">
          <div class="card_info">

            <div *ngIf="colleague"
              style=" text-align:center ;border-bottom:dashed var(--primaryNew) 1px;padding-bottom: 15px;">

              Adresse email : <strong style="color:var(--primaryNew);">{{colleague.emailAddress}}</strong>


            </div>

            <div *ngIf="editMode &&localUserData.emailID==id">
              <form [formGroup]="formTronche" style="margin-bottom: 25px;">

                <div class="editor">
                  <!--div style="pointer-events: none;" class="editor" ></div-->
                  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                  </ngx-editor-menu>


                  <ngx-editor [editor]="editor" formControlName="editorContent">

                  </ngx-editor>


                </div>

              </form>
              <div class="d-flex justify-content-center  align-items-center">
                <button type="button" style="margin-top:-15px;" class="btn btn-secondary"
                  (click)="toggleAction()">Valider
                  <span class="icon-iconValider" style="font-size: 1.3em;"><span class="path1"></span><span
                      class="path2"></span></span>
                </button>
              </div>
            </div>



            <div style="pointer-events: none;" *ngIf="!editMode">
              <form [formGroup]="formTronche" style="margin-bottom: 25px;">

                <div class="editor" style="border: none;">



                  <ngx-editor [editor]="editor" formControlName="editorContent">
                  </ngx-editor>


                </div>

              </form>


            </div>
          </div>
          <div *ngIf="(!submitted)&&(isLoading)" style=" text-align:center ">
            Envoi du message... Merci de patienter.
            <br>
            <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">


          </div>
          <div *ngIf="submitted" style=" text-align:center ">
            {{responseMessage}}
          </div>


          <div *ngIf="colleague" style=" margin-left: auto;margin-right: auto;left: 0; right: 0;text-align: center;">
          <button
            *ngIf="(colleague&&localUserData.email!=colleague.emailAddress)&&(!localUserData.isAdmin)&&(!submitted)&&(!isLoading)"
            type="button" class="btn btn-primary" style="margin-right: 19px;" (click)=" Signal()">
            Signaler

            <span class="icon-iconSignaler" style="font-size: 1.5em;"><span class="path1"></span><span
                class="path2"></span><span class="path3"></span></span>
          </button>
          <button type="button" class="btn btn-primary" style="margin-right: 19px;" (click)=" onBack()">
            Retour
            <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
          </button>

          
            <button *ngIf="(localUserData.isAdmin)&&(colleague.photoURL!='Einstein')" type="button"
              class="btn btn-danger" style="margin-right: 19px;" (click)="deletePicture()">
              Supprimer Photo
              <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>

            <button
              *ngIf="(localUserData.isAdmin)&&(colleague.autoDescriptive)&&(colleague.autoDescriptive!='Pas d\'auto-descriptif')&&(colleague.autoDescriptive!='<p></p>')"
              type="button" class="btn btn-danger" style="margin-right: 19px;" (click)="deleteText()">
              Supprimer Texte
              <span class="icon-iconSupprimer" style="font-size: 1.4em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>

            <a *ngIf="(localUserData.isAdmin)&&(colleague.role=='1,5')">
              <button type="button" class="btn btn-primary" style="margin-right:25px;" (click)=" onNotBlock()">
                Débloquer
              </button>
            </a>

            <a *ngIf="(localUserData.isAdmin)&&(colleague.role!='1,5')">
              <button type="button" class="btn btn-danger" style="margin-right:25px;" (click)=" onBlock()">
                Bloquer
              </button>
            </a>


          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #MailWhenDeletePhoto let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Envoi d'un email</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div *ngIf="(!submitted)&&(!isLoading)" class="modal-body" style="font-size: 13pt;">

    Vous venez d'effacer la photo de {{colleague.firstName}} {{colleague.lastName}},
    si vous voulez lui envoyer un message pour l'en informer , veuillez cliquer sur le
    bouton sur "Envoyer",
    sinon, veuillez cliquer sur "Fermer".
    <br>
    Merci

  </div>
  <div *ngIf="(!submitted)&&(isLoading)" class="modal-body" style="font-size: 13pt;">
    Envoi du message... Merci de patienter.
    <br>
    <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">

  </div>
  <div *ngIf="submitted" class="modal-body" style="font-size: 13pt;">
    <span>{{responseMessage}}</span><br>
    Merci

  </div>

  <div class="modal-footer">
    <button *ngIf="(!submitted)&&(!isLoading)" type="button" class="btn btn-danger"
      (click)="mailPhoto()">Envoyer</button>

    <button *ngIf="(!isLoading)" type="button" class="btn btn-danger"
      (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>

<ng-template #MailWhenDeleteDescription let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Envoi d'un email</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div *ngIf="(!submitted)&&(!isLoading)" class="modal-body" style="font-size: 13pt;">

    Vous venez d'effacer le texte descriptif de {{colleague.firstName}} {{colleague.lastName}},
    si vous voulez lui envoyer un message pour l'en informer , veuillez cliquer sur le
    bouton sur "Envoyer",
    sinon, veuillez cliquer sur "Fermer".
    <br>
    Merci

  </div>
  <div *ngIf="(!submitted)&&(isLoading)" class="modal-body" style="font-size: 13pt;">
    Envoi du message... Merci de patienter.
    <br>
    <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">

  </div>
  <div *ngIf="submitted" class="modal-body" style="font-size: 13pt;">
    <span>{{responseMessage}}</span><br>
    Merci

  </div>

  <div class="modal-footer">
    <button *ngIf="(!submitted)&&(!isLoading)" type="button" class="btn btn-danger"
      (click)="mailDescription()">Envoyer</button>

    <button *ngIf="(!isLoading)" type="button" class="btn btn-danger"
      (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>