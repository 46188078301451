import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object
import { Colleague } from './colleague';
import { AuthService } from '../boilerplate/auth.service';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../boilerplate/local-user';

@Injectable({
  providedIn: 'root'
})
export class ColleaguesService {

  allColleaguesRef: AngularFireList<any>;
  allColleagueRef: AngularFireObject<any>;   // Reference to allColleague object, its an Observable too

  addColleaguePushedRef: string;
  addColleaguePushedKey: string;
  emailID: string;

  public localUserData: LocalUser;
  constructor(
    private db: AngularFireDatabase,
    public authservice: AuthService,
  ) {  //console.log(Date() ,'constructor')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.emailID = this.localUserData.emailID
    }

  }







  AddColleague(colleague: Colleague, colleagueID: string) {
    const myColleagueRef = this.db.database.ref().child('colleagues/' + colleagueID);
    myColleagueRef.set({
      emailAddress: colleague.emailAddress,
      firstName: colleague.firstName,
      lastName: colleague.lastName,
      role: colleague.role,
      quota: colleague.quota,
      photoURL: "Einstein"
    })
     .catch(function (error) {
      console.log(Date(), "Add Colleague failed: " + error.message)
    });

  }


  // Fetch Single Student Object
  GetColleague(id: string) {
    this.allColleagueRef = this.db.object('colleagues/' + id);
    return this.allColleagueRef;
  }
  


  // Fetch Colleagues List
  GetColleaguesList() {
    this.allColleaguesRef = this.db.list('colleagues/');
    return this.allColleaguesRef;
  }




  UpdateColleague(colleague: Colleague) {
    //console.log(Date() ,colleague.emailAddress.replace(/([.])/g, '*'))
    if(Number(colleague.quota)<1){
      colleague.quota='1';
    }
    this.allColleagueRef.update({
      firstName: colleague.firstName,
      lastName: colleague.lastName,
      emailAddress: colleague.emailAddress,
      role: colleague.role,
      quota: colleague.quota,
      // isFavoriteOf: colleague.isFavoriteOf
    })
    .catch(function (error) {
      console.log(Date(), "Update Colleague failed: " + error.message)
    });

    if (this.localUserData.emailID.toLowerCase() == colleague.emailAddress.toLowerCase().replace(/([.])/g, '*')) {
      this.localUserData.quota = Number(colleague.quota)
      localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(this.localUserData).trim(), 'AMortLeCovid').toString());
    }
  }

  UpdateTotalPeriods(userEmailID, totalPeriods){
    let path = 'colleagues/' + userEmailID
    this.allColleagueRef = this.db.object(path);
    this.allColleagueRef.update({ totalPeriods: totalPeriods })
    .then(function () {
      //console.log(Date() ,"Update totalPeriods succeeded.")
    })
    .catch(function (error) {
      console.log(Date(), "Update total failed: " + error.message)
    });
  }

  UpdateCurrentColleagueQuota(userEmailID, quota) {
    if(Number(quota)<1){
     quota='1';
    }
    let path = 'colleagues/' + userEmailID
    this.allColleagueRef = this.db.object(path);
    this.allColleagueRef.update({ quota: quota })
      .then(function () {
        //console.log(Date() ,"Update succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "Update Quota failed: " + error.message)
      });
  }


  UpdateCurrentColleaguePhotoURL(userEmailID, url) {
    let path = 'colleagues/' + userEmailID
    this.allColleagueRef = this.db.object(path);
    this.allColleagueRef.update({ photoURL: url })
      .then(function () {
        //console.log(Date() ,"Remove succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "Update photoURL failed: " + error.message)
      });
  }

  UpdateCurrentColleagueAutoDescriptive(userEmailID, autoDescriptive) {
    let path = 'colleagues/' + userEmailID
    this.allColleagueRef = this.db.object(path);
    this.allColleagueRef.update({ autoDescriptive: autoDescriptive })
      .then(function () {
        //console.log(Date() ,"Remove succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "Update autoDescriptive failed: " + error.message)
      });
  }

  UpdateCurrentColleagueRole(userEmailID, role) {
    let path = 'colleagues/' + userEmailID
    this.allColleagueRef = this.db.object(path);
    this.allColleagueRef.update({ role: role })
      .then(function () {
        //console.log(Date() ,"Remove succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "Update role failed: " + error.message)
      });
  }
  

  // Delete TraCol Object
  DeleteColleague(id: string) {
    this.allColleagueRef = this.db.object('colleagues/' + id);
    this.allColleagueRef.remove()
      .then(function () {
        return true
      })
      .catch(function (error) {
        console.log(Date(), "Remove colleague failed: " + error.message)
      });
  }

  AddIsFavoriteOf(favoriteEmailID, favoriteEmailAddress) {
    this.db.database.ref().child('colleagues/' + favoriteEmailID + "/isFavoriteOf/" + this.emailID).set(favoriteEmailAddress)
    .catch(function (error) {
      console.log(Date(), "Add Favorite link failed: " + error.message)
    });
  }

  DeleteIsFavoriteOf(favoriteEmailID) {
    this.db.database.ref().child('colleagues/' + favoriteEmailID + "/isFavoriteOf/" + this.emailID).remove()
      .then(function () {
        //console.log(Date() ,"Remove succeeded.")
      })
      .catch(function (error) {
        console.log(Date(), "Remove favorite failed: " + error.message)
      });
  }

}
