
  
  
<header class="page-header" id="header" >
  <app-header></app-header>
</header>



 <div class="d-flex justify-content-center  align-items-center border-bottom" style="margin-top: 100px; margin-bottom: 15px;">
   <h1 style="color: white; margin-right: 25px;">Remerciements</h1>
 </div>
 
   
 
 
   <div class="wrapper">
     <div class="cards_wrap"  >
       <div class="card_item"  style="width:90%;margin-bottom: 20px;" >
         <div class="card_inner" >
            <div class="card_top center" >
              <img  src="./assets/images/HeaderBosscool2.png"  width="263px" height="75px" />
             </div>
           
           <div class="card_bottom">
             <div class="card_info">              
                est une application "monopage" développée avec le framework Angular à l'aide Visual Studio code.<br><br>

                <ul style="padding-left: 35px;">
                  <li>Le code, la base du front-end et le back-end ont été réalisés par John DER KINDEREN, enseignant en informatique, qui est à l'origine de cette application.</li>
                  <li>Les dessins, les icônes, la mise en page et une partie du code ont été réalisés par Lucille LAMELYN, enseignante en mathématiques. </li>
                  <li>Les coloris ont été choisis par Lucille L., assistée par John DK. </li>
                  
                </ul>
           
             
              La version 2, de son nom de code "knikketts" a vu le jour en début Octobre 2021, après 3 mois de refonte complète du back-end.
              Nous remercions tous nos bêta-testeurs, toutes les personnes qui ont apporté leurs idées nouvelles et tous les autres
               qui ont participé de près ou de loin à l'élaboration de cette version.
<br>
<br>

              Les développeurs

                          

               
               
             
                  
             
             </div>
             <div class="card_creator">
               <button type="button" class="btn btn-primary" (click)="goBack()">
                  Retour
                  <span class="icon-iconRetour" style="font-size: 1.3em;"></span>
               </button>
             </div>
           </div>
         </div>
       </div>
 
       
 
     </div>
   </div>
    

 
 
  