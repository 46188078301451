<header class="page-header" id="header" >
  <app-header></app-header>
</header>

<div *ngIf="editMode">
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 15px;">
  <h1 style="color: white; margin-right: 25px;">Modification de la page  "Info" </h1>
  <!-- It won't show if there is no allcolleagues data -->
  
</div>

<div style="margin: auto; display: flex; align-items: center; justify-content: center;">
<button type="button" style="margin-right: 15px;" class="btn btn-secondary" (click)="toggleAction()" >Valider 
  <span class="icon-iconValider"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
</button>

<button  type="button" class="btn btn-danger" (click)="Cancel()">
  Annuler
  <span class="icon-iconEffacer"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
</button>
</div>

<div class="container2">
	
	<div class="content">
		<div class="display">
			<!-- <div class="logo">
				<img src="../assets/ngx-editor.svg" />
				<div class="subtitle">Rich Text Editor for angular using ProseMirror</div>
			</div> -->
		</div>

    <form [formGroup]="form" style="margin-bottom: 25px;">

			<div  class="editor" >
				<!--div style="pointer-events: none;" class="editor" ></div-->
				<ngx-editor-menu [editor]="editor"
				 [toolbar]="toolbar"
				 >
				</ngx-editor-menu>
				
			
				<ngx-editor [editor]="editor" formControlName="editorContent">
				</ngx-editor>
				<ngx-editor-floating-menu [editor]="editor">
					<ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
				</ngx-editor-floating-menu>
			
			</div>
			
    </form>
	
		
		

		<!-- <div class="editor">
      <ngx-editor [editor]="editor" [(ngModel)]="editordoc">
      </ngx-editor>
    </div> -->
	</div>
</div>
</div>



<div *ngIf="!editMode">

<div class="d-flex justify-content-center  align-items-center border-bottom" style="margin-top: 100px; margin-bottom: 15px;">
    <h1 style="color: white; margin-right: 25px;">
      Information</h1>
  
  <button *ngIf="localUserData.isAdmin" type="button" class="btn btn-secondary" style="margin-bottom: 15px;" (click)="toggleAction()" >Modifier 
    <span class="icon-iconEdit" style="font-size: 1.3em;"><span class="path1"></span><span
    class="path2"></span></span></button>

</div>
  
    <div class="wrapper">
      <div class="cards_wrap"  >
        <div class="card_item"  style="width:90%;margin-bottom: 20px;" >
          <div class="card_inner" >
            <div class="card_top center" style="color:white" >
              <img  src="./assets/images/HeaderBosscool2.png"  width="263px" height="75px" />
             </div>
            
            <div class="card_bottom">
              
              <div class="card_info">  
                
                <form [formGroup]="form" style="margin-bottom: 25px;">

                 
                    <div style="pointer-events: none;border:none" class="editor" >
                    
                    
                  
                    <ngx-editor [editor]="editor" formControlName="editorContent">
                    </ngx-editor>
                    
                  
                  </div>
                  
                </form>
                 <!--Le travail collaboratif s'inscrit en lien avec les autres composantes de la charge. Il s'agit de généraliser cette pratique 
                 au sein de l'établissement en valorisant le déjà-là, en le rendant plus visible aux yeux de la collectivité. 
                 Ce sera l'opportunité qu'une série de tâches assumées seul puissent l'être désormais collectivement. 
                 <br>
                 <br>
                 Le travail collaboratif concerne plusieurs catégories du personnel : direction adjointe, chef des travaux, chef d'atelier, enseignants, 
                  éducateurs, accompagnateurs CEFA, personnel paramédical, social et psychologique.
                 <br>
                 <br>
                 En principe, le travail collaboratif est presté dans le temps scolaire tel que défini dans le Règlement de travail et au sein 
                 de l'établissement (mais des initiatives peuvent être prises après information à la direction).
                 <br>
                 <br>
                 Le travail collaboratif est le travail avec les autres membres du personnel (ou d'autres établissements ou centres PMS) et le cas échéant 
                 la direction, dans le cadre duquel le membre du personnel effectue une des missions suivantes.
                 <br>
                 <br>
                 <strong style="color:var(--primaryNew); font-size: 18pt;">Ce qui relève du travail collaboratif</strong> <a style="color:var(--primaryNew); font-size: 10t;"> (possibilité de faire du travail collaboratif en inter-écoles)</a>
 
                 <ul style="padding-left: 35px;">
                   <li>Réunion Comité de Pilotage</li>
                   <li>Réunion CPPT, CE, Conseil de participation, ...</li>
                   <li>Co-construction collective de leçons, de séquences pédagogiques ou d'épreuves</li>
                   <li>Observation de leçons par des collègues</li>
                   <li>Co-enseignement</li>
                   <li>Participation à un CAP (jury du Certificat d'Aptitudes pédagogiques)</li>
                   <li>Échange de pratique d'évaluation, de remédiation, de dépassement</li>
                   <li>Concertation verticale et horizontale</li>
                   <li>Coaching d'un nouveau collègue</li>
                   <li>Préparation d'une excursion, d'un voyage scolaire (aspects pédagogiques)</li>
                   <li>Réalisation d'un projet interdisciplinaire</li>
                </ul>

                <div class=center style=" border: 3px dashed var(--primaryNew); padding-right: 6px;padding-left: 6px; ">
                  <strong style="color:var(--primaryNew);">
                  Remarque des développeurs : </strong> Si vous désirez inscrire une personne d'une autre école dans 
                  <span><img src="./assets/images/LogoBosscool.png" width="90px" height="30px" /></span>, veuillez nous contacter.
                </div>
                <br>
                   <strong style="color:var(--primaryNew); font-size: 18pt;">Ce qui ne relève pas du travail collaboratif</strong>

                <ul style="padding-left: 35px;">
                    <li>Conseils de classe</li>
                    <li>Préparation et participation aux portes ouvertes , à des événements visant à promouvoir l'école</li>
                    <li>Surveillance et remplacement</li>
                    <li>Journées pédagogiques et formations</li>
                    <li>Rédaction des bulletins</li>
                    <li>Préparation et participation aux jurys de qualification</li>
                    <li>Activités organisées par l'école dans l'intérêt des élèves</li>
                    <li>Confection des horaires</li>
                    <li>Correction collective d'épreuves</li>
                    <li>Organisation administrative d'un voyage</li>
                </ul>
                   
                <strong style="color:var(--primaryNew); font-size: 18pt;"> Dans l'enseignement secondaire ordinaire, le travail collaboratif est quantifié de la manière suivante : </strong>
                <ul style="padding-left: 35px;">
                    <li>Les membres du <strong style="color:var(--primaryNew);">personnel enseignant </strong>exerçant une fonction à prestations complètes 
                      sont tenus d'accomplir 60 périodes par 
                      année scolaire de travail collaboratif (en dehors de l'horaire hebdomadaire de travail en classe, des SEE obligatoires et 
                      des jours de formation en cours de carrière). À noter que s'ils exercent une fonction à prestations incomplètes, leur volume de 
                      travail collaboratif est proportionnellement réduit. Les professeurs font le travail collaboratif au prorata du nombre d'heures 
                      prestées dans l'école.
                    </li>
                    <li>Pour <strong style="color:var(--primaryNew);">les éducateurs</strong> prestant leur fonction à temps plein : 60 périodes de travail 
                      collaboratif par année scolaire, comprises dans 
                      leur charge hebdomadaire de 36 heures. S'ils exercent une fonction à prestations incomplètes, leur volume de travail collaboratif 
                      sera proportionnellement réduit.
                    </li>
                    <li>Pour <strong style="color:var(--primaryNew);">les accompagnateurs CEFA</strong> exerçant une fonction à prestations complètes : 60 périodes 
                      de travail collaboratif par année scolaire, 
                       à ajouter à leurs 34 périodes hebdomadaires. S'ils exercent une fonction à prestations incomplètes, leur volume de travail collaboratif 
                      sera proportionnellement réduit.</li>
                    <li>Pour <strong style="color:var(--primaryNew);">les membres du personnel paramédical, social et psychologique</strong> : 60 périodes de travail
                       collaboratif par année scolaire, en plus 
                      de leur charge hebdomadaire. S'ils exercent une fonction à prestations incomplètes, leur volume de travail collaboratif sera proportionnellement
                       réduit.
                    </li>
                    <li>Pour <strong style="color:var(--primaryNew);">les fonctions de sélection et de promotion</strong> (à l'exception du directeur) : 60 périodes 
                      de travail collaboratif par année scolaire</li>
                    <ul style="padding-left: 55px;">
                      <li>au-delà des périodes de prestation si elles sont quantifiées en périodes NTPP (30 P chef d'atelier, 36 P coordinateur CEFA);</li>
                      <li>comprises dans leur charge hebdomadaire de 36 heures (direction adjointe, chef de travaux d'atelier).</li>
                    </ul>
                    
                </ul>
                L'idée centrale du travail collaboratif est que ce qui est co-construit prend de la valeur.
                <br>
                <br>
                Une telle démarche favorise la cohérence du cursus, des apprentissages et des évaluations.  Elle contribue au développement professionnel et 
                favorise un bon climat de travail. Elle permet de sortir tous les enseignants de l'isolement et de créer une culture d'école autour d'un projet 
                commun. De nombreuses études montrent que travailler en commun est aussi un levier essentiel du changement. Bref, développer le travail 
                collaboratif peut apporter aux enseignants un mieux-être tout en étant une plus-value pour les élèves.
                <br>
                <br>
                Le Pacte d'Excellence repose sur la conviction qu'il faut favoriser une telle mobilisation des enseignants au sein des écoles dans un cadre qui 
                leur laisse de l'autonomie et qui valorise la diversité de leurs compétences. C'est également un axe fort qui soutient la logique des plans de pilotage.
                <br>
                <br>
                <div style="color:var(--primaryNew);border: 3px dashed var(--primaryNew); padding-right: 6px;padding-left: 6px; ">
                <strong >
                Ce travail collaboratif doit faire l'objet d'un suivi. Chaque membre du personnel concerné est tenu d'effectuer le nombre de périodes prévues par 
                le décret, de remplir et d'imprimer le document et de le remettre au chef d'établissement avant la fin de l'année scolaire.</strong>
              </div>
              <br>
                Merci à chacun d'y être attentif !
                <br>
                <br>
                D. Henno, Chef d'établissement

                
            -->
                
               
 
                
                
              
                   
              
              </div>
              <div class="card_creator">
                <button  type="button" class="btn btn-primary" (click)="goBack()">
                   Retour
                   <span class="icon-iconRetour" style="font-size: 1.3em;"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
  
        
      </div>
     
     

    </div>
     
</div>

