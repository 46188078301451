
  <header class="page-header" id="header" >
    <app-header></app-header>
  </header>

<div class="center" style="color: white;margin-top: 100px;">
  Bienvenue dans <br />
  <span>
    <img src="./assets/images/LogoBosscoolTitre.png" width="180px" height="60px" />
  </span>. <br />
  Veuillez vous inscrire
</div>
<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">

        </div>
        <div class="card_bottom">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="card_info">
              Veuillez entrer votre adresse email professionnelle
              ainsi qu'un mot de passe que vous n'utilisez pas d'habitude sinon, je pourrai le lire ici et l'utiliser
              pour me connecter à votre email ... niark !



              <div style="display: flex;margin: auto;width: 100%;">

                <div style="flex-direction: row;margin: 0px;width: 100%;vertical-align: top;">

                  <input type="text" id="emailLeft" autofocus formControlName="emailLeft"
                    [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailLeft.errors }" class="formControl left "
                    style="margin-bottom: 10px;margin-top: 10px;width: 100%;margin-left: 0px;margin-right: 0px;"
                    placeholder="Préfixe email" />
                  <div *ngIf=" f.emailLeft.errors" class="invalid-feedback" style="width: 100%;margin: auto;">
                    <div *ngIf=" f.emailLeft.errors.required">

                      Le préfixe de l'adresse mail est indispensable.
                    </div>
                    <div *ngIf="!f.emailLeft.pattern">
                      <!-- l'email n'est pas du bon type -->
                      Le préfixe de l'adresse mail doit être valide !
                    </div>
                  </div>

                </div>

                <span style="margin-top:15px"> @</span>

                <div style="flex-direction: row;margin: 0px;width: 100%;vertical-align: top;">

                  <input type="text" id="emailRight" formControlName="emailRight"
                    [ngClass]="{ 'is-invalid': f.emailRight.touched && f.emailRight.errors }" class="formControl left "
                    style="margin-bottom: 10px;margin-top: 10px;width: 100%;margin-left: 0px;margin-right: 0px;"
                    placeholder="Suffixe email" />

                  <div *ngIf=" f.emailRight.errors" class="invalid-feedback" style="max-width: 100%;margin: auto;">
                    <div *ngIf=" f.emailRight.errors.required">
                      Le suffixe de l'adresse mail est indispensable.
                    </div>
                    <div *ngIf="!f.emailRight.pattern">
                      <!-- l'email n'est pas du bon type -->
                      Le suffixe de l'adresse doit être valide !
                    </div>
                  </div>

                </div>


              </div>

              <input type="email" formControlName="email2"
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailRight.touched &&  f.email2.errors }"
                class="formControl" hidden style="margin-bottom: 10px;margin-top: 10px;" />

              <input type="email" formControlName="emailConfirm"
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailRight.touched &&  f.email2.errors }"
                class="formControl" hidden style="margin-bottom: 10px;margin-top: 10px;" />

              <input type="password" formControlName="password"
                [ngClass]="{ 'is-invalid':  f.password.touched && f.password.errors }" class="formControl"
                placeholder="Mot de passe" autocomplete="off" style="margin-bottom: 10px;margin-top: 10px;" />
              <div *ngIf=" f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required" style="color:var(--fushia);">Mot de passe indispensable.</div>
                <div *ngIf="f.password.errors.pattern" style="color:var(--fushia);">
                  Le mot de passe doit contenir au moins 8 caractères dont une majuscule, un chiffre et un caractère
                  spécial.
                </div>
              </div>

              <input type="password" formControlName="confirmPassword"
                [ngClass]="{ 'is-invalid': f.confirmPassword.touched && f.confirmPassword.errors }" class="formControl"
                autocomplete="off" placeholder="Confirmation du mot de passe"
                style="margin-bottom: 10px;margin-top: 10px;" />
              <div *ngIf=" f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required" style="color:var(--fushia);">
                  Confirmation du mot de passe indispensable.
                </div>
                <div *ngIf="!f.confirmPassword.errors.MustMatch && !f.confirmPassword.errors.required"
                  style="color:var(--fushia);">
                  M'enfin, les mots de passe doivent être identiques !
                </div>
              </div>



              <!-- <input type="email" class="formControl" placeholder="Adresse Email" style="margin-bottom: 10px;margin-top: 10px;"#userEmail required>


                <input type="password" class="formControl" placeholder="Mot de passe" style="margin-bottom: 10px;margin-top: 10px;" #userPassword required>
                <input type="password" class="formControl" placeholder="Confirmation du mot de passe" #userPassword2 required>-->
              <div class="card_creator">
                <div class="g-recaptcha" appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)">
                  
                </div>
              </div>

            </div>

            <div class="card_creator">
              <button type="submit" class="btn btn-primary"
                style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px;"
                [disabled]="!registerForm.valid ">
                Inscription
                <span class="icon-iconInscription" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>
            </div>


          </form>


          <div class="redirectToLogin" style="margin-top: 10px; text-align:center;">
            <span>Vous avez déjà un compte <img src="./assets/images/LogoBosscool.png" width="90px" height="30px" />?
              <span class="redirect" routerLink="/sign-in"> <u><br>Connectez-vous !</u></span></span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


