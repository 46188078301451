import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../boilerplate/auth.service';
import { LocalUser } from '../../boilerplate/local-user';
import { ColleagueOfTravcol } from '../colleague-of-travcol';
import { TravCol } from '../travcol';
import { TravcolsService } from '../travcols.service';
import * as CryptoJS from 'crypto-js';
import { ObjectUnsubscribedError } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-all-travcols',
  templateUrl: './all-travcols.component.html',
  styleUrls: ['./all-travcols.component.css']
})
export class AllTravcolsComponent implements OnInit {

  p: number = 1;                      // Settup up pagination variable
  public allTravcols = [];                 // Save travcols data in travcol's array.
  public colleaguesOfTravcol: ColleagueOfTravcol[];
  hideWhenNoTravCol: boolean = false; // Hide travcols data table when no travcol.
  noData: boolean = false;            // Showing No travcol Message, when no travcol in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  closeModal: string;
  isOwnerOfTC: boolean = false;
  public sort: string = 'userEmailID';
  public order: string = 'normal';
  emailID: string;
  public searchTextColleagues: string;
  public searchTextGoals: string;
  public searchTextDecisions: string;
  public searchTextInitials: string;
  public search: string;
  public viewSearch = { "colleaguesListString": false, "goals": false, "decisions": false, "initials": false }
  public Names = ["colleaguesListString", "goals", "decisions", "initials"]
  //public colleaguesList: string
  public userQuotaInMinutes;
  hasValidatedFound: boolean = false;
  toBeDisplayed: boolean;
  localUserData: LocalUser;;
  atLeastOneAcceptation: boolean = false;


  constructor(

    public travcolsApi: TravcolsService, // Inject travcol CRUD services in constructor.

    public authservice: AuthService,

  ) { //console.log(Date() ,'constructor')
  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)


      this.userQuotaInMinutes = this.localUserData.quota * 50
      //console.log(Date() ,this.userQuotaInMinutes)
      this.emailID = this.localUserData.emailID
    }
    let s = this.travcolsApi.GetAllTravColsList();
    s.valueChanges().subscribe(sData => {
      this.dataState(sData); // Initialize travcol's list, when component is ready

      this.allTravcols = [];
      sData.forEach(sElement => {

        //userEmailID = { ...userEmailID, ...sElement }
      

        Object.values(sElement).forEach(ownerID => {


          Object.values(ownerID).forEach(travcol => {
            //console.log(sElement)
            // console.log(ownerID)
            //console.log(travcol)

this.allTravcols.push(travcol)

          });


        });





      });
      this.dataState(this.allTravcols)
    });
  }

  // Using valueChanges() method to fetch simple list of travcols data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState(data) {

    this.preLoader = false;
    if (data.length <= 0) {
      this.hideWhenNoTravCol = false;
      this.noData = true;
    } else {
      this.hideWhenNoTravCol = true;
      this.noData = false;
    }

  }




  setSort(sort: string) {
    this.sort = sort;
    this.order = 'normal';
    // console.log(Date() ,'sort',sort)
  }

  setInvertSort(sort: string) {
    this.sort = sort;
    this.order = 'invert'
  }

  sortBy(prop: string) {
    if (this.allTravcols != null) {
      if (this.order == 'normal'&&this.allTravcols) {
        if(this.sort=='duration'){
          //console.log(Date() ,'bouh')
         // console.log(Date() ,JSON.stringify(this.allTravcols[1][this.sort]))
          return this.allTravcols.sort((a, b) => Number(a[prop]) > Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }

        return this.allTravcols.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
      }
      else if (this.order == 'invert'&&this.allTravcols) {
        if(this.sort=='duration'){
          //console.log(Date() ,'bouh')
         // console.log(Date() ,JSON.stringify(this.allTravcols[1][this.sort]))
          return this.allTravcols.sort((a, b) => Number(a[prop]) < Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }
        return this.allTravcols.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
      }
    }
  }


  /*Search(search) {

    //console.log(Date() ,this.allTravcols)
    // console.log(Date() ,search)
    //console.log(Date() ,this.viewSearch)

    this.searchTextColleagues = '';
    this.searchTextGoals = '';
    this.searchTextDecisions = '';
    this.searchTextInitials = '';

    this.Names.forEach(element => {

      if (element == search) {
        this.viewSearch[element] = true
      }
      else {
        this.viewSearch[element] = false
      }

    });


    //console.log(Date() ,this.viewSearch)
  }*/

  /*EndSearch(search) {
    this.Names.forEach(element => {
      //console.log(Date() ,'element',element)
      //console.log(Date() ,'search',search)
      if (element == search) {
        this.viewSearch[element] = false
      }

    });
    this.searchTextColleagues = '';
    this.searchTextGoals = '';
    this.searchTextDecisions = '';
    this.searchTextInitials = '';
  }
*/


  //vérifie si le user current est ben le propriétaire du travcol
  getmatching(travcol) {
    //console.log(Date() ,travcol)
    if (travcol.ownerID != null) {
      if (travcol.ownerID == this.emailID) {
        return this.isOwnerOfTC = true;
      }
      else {
        return this.isOwnerOfTC = false;
      }
    }
  }

}