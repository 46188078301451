

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LocalUser } from '../../boilerplate/local-user';
import { Colleague } from '../../colleagues/colleague';
import { ColleaguesService } from '../../colleagues/colleagues.service';
import { ColleagueOfTravcol } from '../../travcols/colleague-of-travcol';
import * as CryptoJS from 'crypto-js';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-substitute',
  templateUrl: './substitute.component.html',
  styleUrls: ['./substitute.component.css']
})
export class SubstituteComponent implements OnInit {
  realRole = { 1: "Invité", 2: "Utilisateur", 3: "Administrateur", 4: "Super-Zéro" };
  p: number = 1;                      // Settup up pagination variable
  colleagues: Colleague[];                 // Save colleagues data in TravCol's array.
  hideWhenNoColleague: boolean = false; // Hide colleagues data table when no colleagues.
  noData: boolean = false;            // Showing No colleagues Message, when no colleagues in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)

  userEmailID: string
  order: string = "normal"
  sort: string = "firstName"
  public searchTextLastName: string;
  public searchTextFirstName: string;
  public searchTextEmailAddress: string;
  public searchTextRole: string;
  public searchTextQuota: string;
  public search: string;
  public viewSearch = { "firstName": false, "lastName": false, "emailAddress": false, "realRole": false, "quota": false }
  public Names = ["firstName", "lastName", "emailAddress", "realRole", "quota"]
  colleagueOfTravcol: ColleagueOfTravcol;
  localUserData: LocalUser;


  constructor(
    public colleaguesApi: ColleaguesService, // Inject colleagues CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message
    public router: Router,

  ) {
    // console.log(Date(), 'constructor')
  }



  ngOnInit() {
    //console.log(Date() ,'ngoninitColl')



    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.userEmailID = this.localUserData.emailID
      // console.log(this.afAuth.auth.currentUser)
    }


    //console.log(Date() ,this.userEmailID)
    this.dataState(); // Initialize colleagues's list, when component is ready
    let s = this.colleaguesApi.GetColleaguesList();
    s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
      this.colleagues = [];

      data.forEach(item => {
        let a = item.payload.toJSON();
        //console.log(Date() ,a)
        a['colleagueEmailID'] = item.key;
        a['userEmailID'] = this.userEmailID
        item.payload.hasChild('isFavoriteOf/' + this.userEmailID) ? a['isFavorite'] = true : a['isFavorite'] = false
        a['realRole'] = this.realRole[a['role']]
        this.colleagues.push(a as Colleague);

      })
    })

  }


  // Using valueChanges() method to fetch simple list of colleagues data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState() {
    this.colleaguesApi.GetColleaguesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoColleague = false;
        this.noData = true;
      } else {
        this.hideWhenNoColleague = true;
        this.noData = false;
      }
    })
  }


  sortBy(prop: string) {


    if (prop) {

      if (this.order == 'normal' && this.colleagues) {
        if (this.sort == 'quota') {
          return this.colleagues.sort((a, b) => Number(a[prop]) > Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }
        return this.colleagues.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
      }
      else if (this.order == 'invert' && this.colleagues) {
        if (this.sort == 'quota') {
          return this.colleagues.sort((a, b) => Number(a[prop]) < Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }
        return this.colleagues.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
      }
    }
  } s

  Search(search) {

    this.searchTextLastName = '';
    this.searchTextFirstName = '';
    this.searchTextEmailAddress = '';
    this.searchTextRole = '';
    this.searchTextQuota = '';

    //console.log(Date() ,this.viewSearch)
    this.Names.forEach(element => {
      if (element == search) {
        this.viewSearch[element] = true
      }
      else {
        this.viewSearch[element] = false
      }

    });

    //console.log(Date() ,this.viewSearch)
  }

  EndSearch(search) {
    this.Names.forEach(element => {
      if (element == search) {
        this.viewSearch[element] = false
      }

    });
    this.searchTextLastName = '';
    this.searchTextFirstName = '';
    this.searchTextEmailAddress = '';
    this.searchTextRole = '';
    this.searchTextQuota = '';


  }

  Substitute(colleague) {

    //    console.log(Date() ,'setuserData')
    this.localUserData = {
      uid: this.localUserData.uid,
      email: colleague.emailAddress,
      emailID: colleague.emailAddress.replace(/([.])/g, '*'),
      displayName: colleague.firstName + ' ' + colleague.lastName,
      photoURL: colleague.photoURL,
      emailVerified: true,
      role: 4,
      quota: colleague.quota,
      isAnonymous: colleague.isAnonymous,
      //      initials: colleague.lastName.replace(/ /g,"").substring(0, 4).toUpperCase()+ colleague.firstName.replace(/ /g,"").substring(0, 2).toUpperCase(),
      initials: colleague.lastName.replace(/ /g, "").substring(0, 4).toUpperCase() + colleague.firstName.replace(/ /g, "").substring(0, 2).toUpperCase(),
      shortName: colleague.firstName + ' ' + colleague.lastName.substring(0, 1).toUpperCase(),
      isLogged: true,
      isAdmin: true,
      isInvite: (colleague.role == 1) ? true : false
    }
    //console.log(JSON.stringify(this.localUserData))
    localStorage.removeItem('userData')
    localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(this.localUserData).trim(), 'AMortLeCovid').toString());

 
   // this.toastr.success("Bienvenue oncle réincarné en "+ this.localUserData.displayName+ "!", 'RÉINCARNATION RÉUSSIE');
  
    this.router.navigate(['profile'])
    .then(() => {
     window.location.reload();
   })
   .catch(function (error) {
    console.log(Date() , error.message)
    });
 
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}


