import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TravcolsService } from '../travcols.service';
import { ActivatedRoute, Router } from "@angular/router"; // ActivatedRoue is used to get the current associated components information.
import { Location } from '@angular/common';  // Location service is used to go back to previous component
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/components/boilerplate/auth.service';
//import { globallocalUserData } from 'src/app/app.component';
import { FavoritesService } from '../../favorites/favorites.service';
import { Favorites } from '../../favorites/favorites';
import { TravCol } from '../travcol';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ParametersService } from '../../others/parameters.service';

@Component({
  selector: 'app-edit-travcol',
  templateUrl: './edit-travcol.component.html',
  styleUrls: ['./edit-travcol.component.css']
})
export class EditTravColComponent implements OnInit {


  public editForm: FormGroup;  // Define FormGroup to travcol's edit form
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  dropdownSettings: any = {};
  initialData: TravCol[];                 // Save allcolleagues data in TravCol's array.
  public favorites: Favorites[];
  selectedItems = []
  newTravcol: TravCol;
  localUserData: LocalUser;
  parameters: Object;
  todayDate: Date = new Date();
  public pivotDate = new Date(this.todayDate.getFullYear(), 6, 8)
  minDate: string;
  maxDate: string;
;
 // userEmailID=this.travcolsApi.localUserData.emailID;

  constructor(
    private travcolsApi: TravcolsService,       // Inject CRUD API in constructor
    private fb: FormBuilder,            // Inject Form Builder service for Reactive forms
    private location: Location,         // Location service to go back to previous component
    private actRoute: ActivatedRoute,   // Activated route to get the current component's inforamation
    private router: Router,             // Router service to navigate to specific component
    private toastr: ToastrService,       // Toastr service for alert message
    private authservice: AuthService,
    public favoritesApi: FavoritesService,
    public parametersApi: ParametersService,
  ) { // console.log(Date() ,'constructor')
  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit')


    if (localStorage.getItem('userData') !== null ) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
  
    }

    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.parameters=data.payload.toJSON()
      this.pivotDate=new Date (this.parameters['pivotDate'])
    })

    
    this.updateTravColData();   // Call updateTravColData()
    var travcol = []
    travcol['userEmailID'] = this.actRoute.snapshot.paramMap.get('userEmailID');
    travcol['ownerID'] = this.actRoute.snapshot.paramMap.get('ownerID');
    travcol['key'] = this.actRoute.snapshot.paramMap.get('key');
    this.travcolsApi.GetTravCol(travcol).valueChanges().subscribe(initialData => {
      //console.log(Date() ,initialData)
      this.initialData = initialData
     

      if(initialData){
        
      Object.keys(initialData.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
        //console.log(Date() ,colleaguesOfTravcolKey)
        if (this.localUserData.emailID != colleaguesOfTravcolKey) {
          this.selectedItems.push({ colleagueEmailID: colleaguesOfTravcolKey, fullName: initialData['colleaguesOfTravcol'][colleaguesOfTravcolKey]['fullName'] })
        }
      });
      //console.log(Date() ,this.selectedItems)
      if(!initialData['typeOfTC']){
        this.initialData['typeOfTC']='NoData';
      }
      if(!initialData['decisions']){
        this.initialData['decisions']='NoData';
      }
      if(!initialData['colleaguesOfTravcol2']){
        initialData['colleaguesOfTravcol2']=''
      }
      if(!initialData['creationDate']){
        initialData['creationDate']='xx'
      }
      if(!initialData['modificationDate']){
        initialData['modificationDate']='xx'
      }
      //console.log('data',initialData)
      this.editForm.setValue(initialData)
      this.editForm.controls.colleaguesOfTravcol.setValue(this.selectedItems)
    }
      let s = this.favoritesApi.GetFavoritesList(this.localUserData.emailID);
      s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
        let i = 0;
        this.favorites = [];
        data.forEach(item => {
          if (item['key'] == this.localUserData.emailID) {
            this.favorites.splice(i, 1)
          }
          let a = item.payload.toJSON();
          this.favorites.push(a as Favorites)
          this.favorites.sort((n1, n2) => {
            if (n1['fullName'] > n2['fullName']) {
              return 1;
            }
            if (n1['fullName'] < n2['fullName']) {
              return -1;
            }
            return 0;
          });

          i++;
        })
      })


    })



    this.dropdownSettings = {
      "singleSelection": false,
      "defaultOpen": false,

      "idField": "colleagueEmailID",
      "textField": 'fullName',
      "selectAllText": "Select All",
      "unSelectAllText": "UnSelect All",
      "enableCheckAll": false,
      "itemsShowLimit": 12,
      "allowSearchFilter": true,
      "searchPlaceholderText": 'Rechercher un(e) collègue',

    };

 

  }

  //vérifie si le user current est ben le propriétaire du travcol
  getmatching() {
    //console.log(Date() ,this.localUserData.emailID)
    //console.log(Date() ,this.initialData['ownerID'])
    if (this.initialData != null) {
      if (this.localUserData.emailID == this.initialData['ownerID']) {
        return true;
      }
      else {
        return false;
      }
    }
  }


  onItemSelect(item: any) {

  }



  // Accessing form control using getters
  get date() {
    return this.editForm.get('date');
  }


  get duration() {
    return this.editForm.get('duration');
  }

  get colleaguesOfTravcol() {
    return this.editForm.get('colleaguesOfTravcol');
  }

  get goals() {
    return this.editForm.get('goals');
  }

  get decisions() {
    return this.editForm.get('decisions');
  }
  get typeOfTC() {
    return this.editForm.get('typeOfTC');
  }

 


  // Contains Reactive Form logic
  updateTravColData() {
    this.editForm = this.fb.group({
      date: ['', [Validators.required, Validators.minLength(2),this.minMaxDateValidator.bind(this)]],

      //date: ['', [Validators.required, Validators.minLength(2)]],
      duration: ['', [Validators.pattern('^[0-9]+$')]],
      colleaguesOfTravcol: ['', [Validators.required]],
      goals: ['', [Validators.required,this.noWhitespaceValidator]],
      decisions: ['', [Validators.required,this.noWhitespaceValidator]],
      ownerID: [],
      initials: [],
      colleagueEmailID: [],
      //isActivable: [],
      isActivable: [],
      key: [],
      userEmailID: [],
      fullName:[],
      colleaguesListString:[],
      colleaguesOfTravcol2:[],
      creationDate:[],
      modificationDate:[],
      typeOfTC : ['', [Validators.required,this.noWhitespaceValidator]],
    })
  }


  public calculateMinMaxDate(pivotDate: Date) {
    let lMinDate: Date = new Date();
    let lMaxDate: Date = new Date();
    if (this.todayDate > pivotDate) {
      lMinDate = new Date(Number(this.todayDate.getFullYear()), this.pivotDate.getMonth(), this.pivotDate.getDate())
      lMaxDate = new Date(Number(this.todayDate.getFullYear()) + 1, this.pivotDate.getMonth(), this.pivotDate.getDate())

    }
    else {
      lMinDate = new Date(Number(this.todayDate.getFullYear()) - 1, this.pivotDate.getMonth(), this.pivotDate.getDate())
      lMaxDate = new Date(Number(this.todayDate.getFullYear()), this.pivotDate.getMonth(), this.pivotDate.getDate())
    }

    this.minDate = lMinDate.getFullYear() + '-' + ("0" + (lMinDate.getMonth() + 1)).slice(-2) + '-' + ("0" + lMinDate.getDate()).slice(-2);
    this.maxDate = lMaxDate.getFullYear() + '-' + ("0" + (lMaxDate.getMonth() + 1)).slice(-2) + '-' + ("0" + lMaxDate.getDate()).slice(-2);
    //console.log('min:' + this.minDate + ' max :' + this.maxDate)


  }


  public minMaxDateValidator(control: FormControl) {
    this.calculateMinMaxDate(this.pivotDate)
    if (new Date(control.value) < new Date(this.minDate)) {
      return "false"
    }
    else if (new Date(control.value) > new Date(this.maxDate) ) {
      return "false"
    }
    else {
      return null
    }

  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

  // Go back to previous component
  goBack() {
    this.location.back();
  }

  onBack() {
    this.router.navigate(['travcols-list']);

  }

  // Below methods fire when somebody click on submit button
  updateForm() {

    //console.log(Date() ,this.initialData)

//extermination de l'original
    Object.keys(this.initialData['colleaguesOfTravcol']).forEach(colleaguesOfTravcolKey => {
      //console.log(Date() ,colleaguesOfTravcolKey)
      this.travcolsApi.DeleteTravColForOneColleague(colleaguesOfTravcolKey, this.initialData);
    })

//création d'un nouveau TC avec la nouvelle config

   this.editForm.value.colleaguesOfTravcol.push({
      colleagueEmailID: this.localUserData.emailID,
      fullName: this.localUserData.displayName,
    });

    var travcolCustomKey = this.travcolsApi.CreateTravcolKeyRef({});
    this.newTravcol = this.editForm.value
    var objNewTravcol = Object.assign({}, this.newTravcol)
    
      


    this.editForm.value.colleaguesOfTravcol.forEach(
      element => {

        objNewTravcol['colleagueEmailID'] = element['colleagueEmailID']
        objNewTravcol['key'] = travcolCustomKey
        objNewTravcol['colleaguesOfTravcol'] = ''
        this.travcolsApi.AddTravColWithCustomKey(travcolCustomKey, objNewTravcol)
        objNewTravcol['isActivable'] = false
        
        let aaa=[]
        this.editForm.value.colleaguesOfTravcol.forEach(element2 => {
          var newColleagueOfTravcol = {
            userEmailID: "Updated by Travcolservice",
            colleagueEmailID: element2['colleagueEmailID'],
            travcolKey: travcolCustomKey,
            hasValidated: false,
            hasActivated: true,
            fullName: element2.fullName,
            //travcolIsActivated : objNewTravcol.isActivable
            colleaguesListString : aaa.push(' ' +element2['fullName']),
            
            

          }
          if(element2['colleagueEmailID']==this.localUserData.emailID){
            newColleagueOfTravcol.hasValidated=true
          }
          this.travcolsApi.AddColleagueOfTravcol(objNewTravcol,newColleagueOfTravcol)
          
        });
        objNewTravcol['colleaguesListString']=aaa.toString().replace(/,/g, '');
       

      })

    this.toastr.success('Le travail collaboratif du ' + this.editForm.controls['date'].value.split('-').reverse().join('-') + ' a été modifié avec succès !'); // Show success message when data is successfully submited
    this.router.navigate(['travcols-list']);               // Navigate to travcol's list page when travcol data is updated
  }

  duplicate() {


//création d'un nouveau TC avec la nouvelle config

this.editForm.value.colleaguesOfTravcol.push({
  colleagueEmailID: this.localUserData.emailID,
  fullName: this.localUserData.displayName,
});

var travcolCustomKey = this.travcolsApi.CreateTravcolKeyRef({});
this.newTravcol = this.editForm.value
var objNewTravcol = Object.assign({}, this.newTravcol)

objNewTravcol['creationDate'] = new Date().toLocaleString('fr-BE', {  timeZone: 'Europe/Brussels'})
objNewTravcol['colleaguesListString']=""
let collListString=[]

this.editForm.value.colleaguesOfTravcol.forEach(
  element3 => {
    collListString.push(' ' +element3['fullName'])
  })
this.editForm.value.colleaguesOfTravcol.forEach(
  element => {

    objNewTravcol['colleagueEmailID'] = element['colleagueEmailID']
    objNewTravcol['key'] = travcolCustomKey
    objNewTravcol['colleaguesOfTravcol'] = ''
    objNewTravcol['isActivable'] = false
    objNewTravcol['fullName'] =this.localUserData.displayName
    objNewTravcol['colleaguesListString']=collListString.toString().replace(/,/g, '');

    
    this.travcolsApi.AddTravColWithCustomKey(travcolCustomKey, objNewTravcol)

    this.editForm.value.colleaguesOfTravcol.forEach(element2 => {
      var newColleagueOfTravcol = {
        userEmailID: "Updated by Travcolservice",
        colleagueEmailID: element2['colleagueEmailID'],
        travcolKey: travcolCustomKey,
        hasValidated: false,
        hasActivated: true,
        fullName: element2.fullName,
      }
      if(element2['colleagueEmailID']==this.localUserData.emailID){
        newColleagueOfTravcol.hasValidated=true
      }

      this.travcolsApi.AddColleagueOfTravcol(objNewTravcol,newColleagueOfTravcol)

    });
  
  })
this.toastr.success('Le travail collaboratif du ' + this.editForm.controls['date'].value.split('-').reverse().join('-') + ' a été ajouté avec succès !'); // Show success message when data is successfully submited
this.router.navigate(['travcols-list']);               // Navigate to travcol's list page when travcol data is updated
}


}