<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom" style="margin-top: 100px; margin-bottom: 15px;">
    <h1 style="color: white; margin-right: 25px;">
      Déconnexion automatique</h1>
  </div>
  
    
  
  
    <div class="wrapper">
      <div class="cards_wrap"  >
        <div class="card_item"  style="width:90%;margin-bottom: 20px;" >
          <div class="card_inner" >
            <div class="card_top center" style="color:white" >
              <img  src="./assets/images/HeaderBosscool2.png"  width="263px" height="75px" />
             </div>
            
            <div class="card_bottom">
              
              <div class="card_info" style="text-align: center;" >    
 Vous allez être déconnecté(e) automatiquement dans  <countdown #cd (event)="handleEvent($event)" [config]="config"></countdown> secondes         
 
 
<div class="card_creator">

    <button type="button" class="btn btn-primary" style="margin-right:25px;"
    (click)=" onTravcolsList()" >
    Retour
    <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
   </button>
   <button type="button" class="btn btn-primary" style="margin-right:25px;"
    (click)=" authservice.SignOut()" >
    Ok
    <span class="icon-iconValider"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
   </button>

  </div>            
              
                   
              
              </div>
              </div>
          </div>
        </div>
  
        
  
      </div>
    </div>
     

  
  
   
