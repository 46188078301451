import { Component, OnInit } from '@angular/core';
import { ColleaguesService } from '../colleagues.service';  // CRUD API service class
import { ToastrService } from 'ngx-toastr';      // Alert message using NGX toastr
import { Colleague } from 'src/app/components/colleagues/colleague';
import { FavoritesService } from 'src/app/components/favorites/favorites.service';
import { AuthService } from 'src/app/components/boilerplate/auth.service';
import { ColleagueOfTravcol } from "src/app/components/travcols/colleague-of-travcol";
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';



//import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-colleagues-list',
  templateUrl: './colleagues-list.component.html',
  styleUrls: ['./colleagues-list.component.css']
})
export class ColleaguesListComponent implements OnInit {

  realRole = { 1: "Invité", 2: "Utilisateur", 3: "Administrateur", 4: "Super-Zéro" };
  p: number = 1;                      // Settup up pagination variable
  colleagues: Colleague[];                 // Save colleagues data in TravCol's array.
  hideWhenNoColleague: boolean = false; // Hide colleagues data table when no colleagues.
  noData: boolean = false;            // Showing No colleagues Message, when no colleagues in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)

  userEmailID: string
  public sort: string = 'firstName';
  public order: string = 'normal';
  public searchTextLastName: string;
  public searchTextFirstName: string;
  public searchTextEmailAddress: string;
  public searchTextRole: string;
  public searchTextQuota: string;
  public search: string;
  public viewSearch = { "firstName": false, "lastName": false, "emailAddress": false, "realRole": false, "quota": false }
  public Names = ["firstName", "lastName", "emailAddress", "realRole", "quota"]
  colleagueOfTravcol: ColleagueOfTravcol;
  localUserData: LocalUser;
  closeModal: string;

  constructor(
    public colleaguesApi: ColleaguesService, // Inject colleagues CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message
    public favoritesApi: FavoritesService,
    public authservice: AuthService,
    private modalService: NgbModal,
  


  ) {
   // console.log(Date(), 'constructor')
  }


  ngOnInit() {
     //console.log(Date() ,'ngoninitColl')

    

    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.userEmailID = this.localUserData.emailID
      // console.log(this.afAuth.auth.currentUser)
    }


    //console.log(Date() ,this.userEmailID)
    this.dataState(); // Initialize colleagues's list, when component is ready
    let s = this.colleaguesApi.GetColleaguesList();
    s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
      this.colleagues = [];

      data.forEach(item => {
        let a = item.payload.toJSON();
        //console.log(Date() ,a)
        a['colleagueEmailID'] = item.key;
        a['userEmailID'] = this.userEmailID
        item.payload.hasChild('isFavoriteOf/' + this.userEmailID) ? a['isFavorite'] = true : a['isFavorite'] = false
        a['realRole'] = this.realRole[a['role']]
        if (a['role'].toString()=="1,5") {a['realRole'] ="Utilisateur sans photo"}
        this.colleagues.push(a as Colleague);

      })
      //console.log(Date() ,this.colleagues)
    })

  }
  

  // Using valueChanges() method to fetch simple list of colleagues data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState() {
    this.colleaguesApi.GetColleaguesList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if (data.length <= 0) {
        this.hideWhenNoColleague = false;
        this.noData = true;
      } else {
        this.hideWhenNoColleague = true;
        this.noData = false;
      }
    })
  }

  // Method to delete colleagues object
  DeleteColleague(colleague) {
    if (Number(this.localUserData.role) < Number(colleague.role)) return
    //console.log(colleague.colleagueEmailID)
    if (window.confirm('Etes-vous "certes zinzin" de vouloir effacer ce collègue ?')) { // Asking from user before Deleting colleagues data.
      this.colleaguesApi.DeleteColleague(colleague.colleagueEmailID)
      // Using Delete colleagues API to delete colleagues.
      this.toastr.success('Le collègue ' + colleague.firstName + ' ' + colleague.lastName + ' est bien effacé pour toujours à jamais, tant pis pour votre amitié !'); // Alert message will show up when colleagues successfully deleted.
    }
  }

  
  ToggleFavorite(choosenColleague: Colleague) {
    //console.log(Date() ,choosenColleague)
    if (choosenColleague.colleagueEmailID == this.userEmailID) {
      this.toastr.error(`Voyons, même si vous vous aimez narcissiquement, il n'est pas possible de vous "auto-ajouter" !`);
      return
    }
    //console.log(Date() ,this.colleagues)

    var myIndex = this.colleagues.findIndex(function (item) {
      //console.log(Date() ,item)
      if (item.colleagueEmailID == choosenColleague.colleagueEmailID) {
        //console.log(Date() ,item.colleagueEmailID)
      }
      return item.colleagueEmailID === choosenColleague.colleagueEmailID
    });



    if (!choosenColleague.isFavorite) {
      //console.log(Date() ,this.userEmailID)
      this.favoritesApi.AddFavorite(choosenColleague);
      this.colleaguesApi.AddIsFavoriteOf(choosenColleague.colleagueEmailID, choosenColleague.firstName + ' ' + choosenColleague.lastName);
      //console.log(Date() ,this.colleagues)
      //console.log(Date() ,myIndex)
      this.colleagues[myIndex].isFavorite = true;
      this.toastr.success('Le collègue ' + choosenColleague.firstName + ' ' + choosenColleague.lastName + ' a bien été ajouté dans vos favoris! On finira par tout savoir !');
    }

    else {
      this.favoritesApi.DeleteFavorite(choosenColleague);
      this.colleaguesApi.DeleteIsFavoriteOf(choosenColleague.colleagueEmailID);
      this.colleagues[myIndex].isFavorite = false;
      this.toastr.success("Voilà, " + choosenColleague.firstName + ' ' + choosenColleague.lastName + " n'est maintenant plus un de vos favoris! Tant pis pour votre amitié !");


    }


  }





  setSort(sort: string) {
    this.sort = sort;
    this.order = 'normal';
    
  }


  setInvertSort(sort: string) {
    this.sort = sort;
    this.order = 'invert'
    
  }

  sortBy(prop: string) {

    
    if (prop) {
   
      if (this.order == 'normal' && this.colleagues) {
        if(this.sort=='quota'){
          return this.colleagues.sort((a, b) => Number(a[prop]) > Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }
        return this.colleagues.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
      }
      else if (this.order == 'invert' && this.colleagues) {
        if(this.sort=='quota'){
          return this.colleagues.sort((a, b) => Number(a[prop]) < Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }
        return this.colleagues.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
      }
    }
  }





  Search(search) {

    this.searchTextLastName = '';
    this.searchTextFirstName = '';
    this.searchTextEmailAddress = '';
    this.searchTextRole = '';
    this.searchTextQuota = '';

    //console.log(Date() ,this.viewSearch)
    this.Names.forEach(element => {
      if (element == search) {
        this.viewSearch[element] = true
      }
      else {
        this.viewSearch[element] = false
      }

    });

    //console.log(Date() ,this.viewSearch)
  }

  EndSearch(search) {
    this.Names.forEach(element => {
      if (element == search) {
        this.viewSearch[element] = false
      }

    });
    this.searchTextLastName = '';
    this.searchTextFirstName = '';
    this.searchTextEmailAddress = '';
    this.searchTextRole = '';
    this.searchTextQuota = '';


  }

  triggerModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
    .then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    })
    .catch(function (error) {
      console.log(Date(), error.message)
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



}

