<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
    style="margin-top: 100px; margin-bottom: 10px;">
    <h1 style="color: white; margin-right: 25px;">Liste de tous les travaux collaboratifs </h1>
    <!-- It won't show if there is no travcol data -->
</div>

<div class="pricing-header mx-auto">

    <!-- Preloader shows before the data loads-->
    <div class="no-data text-center" *ngIf="preLoader">
        <img src="assets/preloader.svg" class="preloader-icon" alt="No travcol">
    </div>

    <!-- No data shows when their is no travcol data available -->
    <div class="no-data text-center" *ngIf="noData">
        <img src="assets/images/NoTravcol2.png" width="30%" height="30%" class="nodata-msg" alt="">
        <p style="color: white; ">Pas de travail collaboratif à lister</p>
        <a *ngIf="localUserData &&!localUserData.isInvite" routerLink="/add-travcol"
            class="btn btn-secondary" style="margin-bottom: 25px;margin-top: -10px;">
            Ajouter un TC
            <span class="icon-iconAjouter" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>

        </a>
    </div>


    <div class="wrapper" style="min-width: 100%;" *ngIf="hideWhenNoTravCol">
        <div class="cards_wrap" style="min-width: 100%;">
            <div class="card_item" style="min-width: 100%;margin-bottom: 20px;">
                <div class="card_inner" style="min-width: 100%;">

                    <div class="card_top center" style="min-width: 100%;">
                    </div>
                    <div class="card_bottom" style="min-width: 100%;">
                        <div class="card_info" style="min-width: 100%;">
                            <!-- Showing travcols data -->
                            <div style="overflow-x:auto" id="pdfTable">
                                <table scrollx="true"
                                    style="margin-left:auto;margin-right:auto;min-width: 100%;margin-bottom: 20px;">

                                    <tr>
                                        <th class="center"> User ID

                        <span (click)="setSort('userEmailID')">
                        <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='userEmailID' && this.order =='normal')"
                          class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                        <span ngbTooltip="A-> Z" *ngIf="this.sort=='userEmailID' && this.order =='normal'"
                          class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>


                      <span (click)="setInvertSort('userEmailID')">
                        <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='userEmailID' && this.order =='invert')"
                          class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                        <span ngbTooltip="Z -> A" *ngIf="this.sort=='userEmailID' && this.order =='invert'"
                          class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                            class="path1"></span><span class="path2"></span></span>
                      </span>

                                        </th>

                                        <th class="center">Owner ID

                                            <span (click)="setSort('fullName')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='fullName' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='fullName' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('fullName')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='fullName' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='fullName' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                        </th>

                                        <th class="center">Initiales

                                            <span (click)="setSort('initials')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='initials' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='initials' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('initials')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='initials' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='initials' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                        </th>

                                        <th class="center">TC ID 

                                            <span (click)="setSort('key')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='key' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='key' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('key')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='key' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='key' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                        </th>
                                        <th class="center"> Date 
                                            <span (click)="setSort('date')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='date' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='date' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('date')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='date' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='date' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>

                                        </th>
                                        <th class="center">Duration 
                                            <span (click)="setSort('duration')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='duration' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='duration' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('duration')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='duration' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='duration' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                        </th>
                                        <th class="center">Objectifs
                                            <span (click)="setSort('goals')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='goals' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='goals' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('goals')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='goals' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='goals' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                            
                                        </th>
                                        <th class="center"> Décisions
                                            <br>
                                            <span (click)="setSort('decisions')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='decisions' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='decisions' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('decisions')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='decisions' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='decisions' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                        </th>
                                        <th class="center">Is activable

                                            <span (click)="setSort('isActivable')">
                                                <span ngbTooltip="A -> Z" *ngIf="!(this.sort=='isActivable' && this.order =='normal')"
                                                  class="icon-iconTriHaut" style="font-size: 1.5em; margin-right:6px;cursor:pointer"></span>
                                                <span ngbTooltip="A-> Z" *ngIf="this.sort=='isActivable' && this.order =='normal'"
                                                  class="icon-iconTriHautCouleur" style="font-size: 1.5em;margin-right:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                        
                        
                                              <span (click)="setInvertSort('isActivable')">
                                                <span ngbTooltip="Z -> A" *ngIf="!(this.sort=='isActivable' && this.order =='invert')"
                                                  class="icon-iconTriBas" style="font-size: 1.5em; margin-left:6px;cursor:pointer"></span>
                                                <span ngbTooltip="Z -> A" *ngIf="this.sort=='isActivable' && this.order =='invert'"
                                                  class="icon-iconTriBasCouleur" style="font-size: 1.5em;margin-left:6px;cursor:pointer"><span
                                                    class="path1"></span><span class="path2"></span></span>
                                              </span>
                                        </th>
                                        <th class="center">Collègues </th>

                                    </tr>

                                    <tbody *ngIf="hideWhenNoTravCol">
                                        <!-- *ngFor loop iterates over travcol array and fetch the travcol's data -->
                                        <!-- paginate pipe will add pagination in travcol's list, it won't show if items are less then 7 -->
                                        <tr *ngFor="let travcol of sortBy(this.sort) ;  let i=index">
                                            <td class="center" style="font-weight: bold;">{{travcol.userEmailID}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.fullName}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.initials}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.key}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.date|date:'dd/MM/yyyy'}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.duration}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.goals}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.decisions}} </td>
                                            <td class="center" style="font-weight: bold;">{{travcol.isActivable}} </td>
                                            <td class="center" style="font-weight: bold;">
                                                {{travcol.colleaguesListString}} </td>

                                            <!--  <td class="center" style="font-weight: bold;" [ngStyle]="{
                        'color'
                        : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                        : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable)? 'var(--disabled)' 
                        : 'white' 
                      }">
                                                {{travcol.date |
                                                date:'dd/MM/yyyy'}}</td>
                                            <td class="center" style="font-weight: bold;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                                                {{travcol.duration}}</td>
                                            <td style="font-weight: bold;">

                                                <ng-container
                                                    *ngFor="let colleagues of travcol['colleaguesOfTravcol2'] ">

                                                    <li *ngIf="travcol.colleaguesOfTravcol[colleagues.key]" [ngStyle]="{
                          'color'
                          : !travcol.isActivable ? 'var(--disabled)'
                          : travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] && !travcol.colleaguesOfTravcol[colleagues.key]['hasActivated'] ? 'var(--disabled)' 
                          : !travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[colleagues.key]['hasValidated'] && (!travcol.colleaguesOfTravcol[colleagues.key]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                                                        {{colleagues.fullName}}
                                                    </li>
                                                </ng-container>
                                            </td>
                                            <td style="font-weight: bold; ;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                                                {{travcol.goals}}
                                            </td>
                                            <td style="font-weight: bold;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                                                {{travcol.decisions}}</td>
                                            <td class="center" style="font-weight: bold;" [ngStyle]="{
                          'color'
                          : !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] ? 'var(--accepted)'
                          : travcol.colleaguesOfTravcol[travcol.userEmailID]['hasValidated'] && (!travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']|| !travcol.isActivable) ? 'var(--disabled)' 
                          : 'white' 
                        }">
                                                <a
                                                    [ngbTooltip]="(travcol.ownerID==localUserData.emailID)?travcol.fullName +' (Propriétaire)':travcol.fullName">{{travcol.initials}}</a>
                                            </td>

-->



                                        </tr>
                                    </tbody>


                                </table>
                                <a>
                                    <pagination-controls class="my-pagination" (pageChange)="p = $event" autoHide="true"
                                        responsive="true" previousLabel="Précédent" nextLabel="Suivant">
                                    </pagination-controls>
                                </a>


                            </div>
                            <!--<div> <button  (click)="downloadAsPDF()">Export To PDF</button></div>   -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>