
    <header class="page-header" id="header" >
      <app-header></app-header>
    </header>
    
<div class="center" style="color: white;margin-top: 100px;">

  Bienvenue dans <br />
  <span>
    <img src="./assets/images/LogoBosscoolTitre.png" width="180px" height="60px" />
  </span> . <br />

  

  Veuillez vous connecter
</div>
<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">

        </div>
        <div class="card_bottom">
          <form [formGroup]="Form" (ngSubmit)="onSubmit()">
            <div class="card_info">
              Veuillez entrer votre adresse email professionnelle
              et le mot de passe que vous avez créé lors de votre enregistrement dans <span>
                <img src="./assets/images/LogoBosscool.png" width="90px" height="30px" />
              </span>.<br>
              Si vous venez de demander un nouveau mot qui passe, veuillez penser à fouiller dans vos "spasmes".


              <div style="display: flex;margin: auto;width: 100%;">
              
              <div style="flex-direction: row;margin: 0px;width: 100%;vertical-align: top;">
               
              <input type="text" id="emailLeft" autofocus formControlName="emailLeft" 
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailLeft.errors }" class="formControl left "
                style="margin-bottom: 10px;margin-top: 10px;width: 100%;margin-left: 0px;margin-right: 0px;" placeholder="Préfixe email"
                />
                <div *ngIf=" f.emailLeft.errors" class="invalid-feedback" style="width: 100%;margin: auto;">
                  <div *ngIf=" f.emailLeft.errors.required">
                    
                    Le préfixe de l'adresse mail est indispensable.
                  </div>
                  <div *ngIf="!f.emailLeft.pattern">
                    <!-- l'email n'est pas du bon type -->
                    Le préfixe de l'adresse mail doit être valide !
                  </div>
                </div>
                   
              </div>

              <span style="margin-top:15px"> @</span>
             
              <div style="flex-direction: row;margin: 0px;width: 100%;vertical-align: top;">

              <input type="text" id="emailRight"  formControlName="emailRight"
                [ngClass]="{ 'is-invalid': f.emailRight.touched && f.emailRight.errors }" class="formControl left "
                style="margin-bottom: 10px;margin-top: 10px;width: 100%;margin-left: 0px;margin-right: 0px;" placeholder="Suffixe email"
                />

                <div *ngIf=" f.emailRight.errors" class="invalid-feedback" style="max-width: 100%;margin: auto;">
                  <div *ngIf=" f.emailRight.errors.required">
                    Le suffixe de l'adresse mail est indispensable.
                  </div>
                  <div *ngIf="!f.emailRight.pattern">
                    <!-- l'email n'est pas du bon type -->
                    Le suffixe de l'adresse doit être valide !
                  </div>
                </div>
              
              </div>

              
            </div>


              
           
              

              <input type="email" formControlName="email2"
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailRight.touched &&  f.email2.errors }" class="formControl" hidden
                style="margin-bottom: 10px;margin-top: 10px;" />

              <input type="email" formControlName="emailConfirm"
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailRight.touched &&  f.email2.errors }" class="formControl" hidden
                style="margin-bottom: 10px;margin-top: 10px;" />

                
              <input type="password" formControlName="password" autocomplete="off"
                [ngClass]="{ 'is-invalid':  f.password.touched && f.password.errors }" class="formControl left"
                placeholder="Mot de passe" style="margin-bottom: 10px;margin-top: 10px;" />
              <div *ngIf=" f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Mot de passe indispensable.</div>
                <div *ngIf="f.password.errors.pattern">
                  Le mot de passe doit contenir au moins 8 caractères dont une majuscule, un chiffre et un caractère
                  spécial.
                </div>
              </div>


              <!-- <input type="email" class="formControl left " style="margin-bottom: 10px;margin-top: 10px;"
              placeholder="Adresse email professionnelle" #userName required>
            <input type="password" class="formControl left" placeholder="Mot de Passe" #userPassword required>-->

              <div class="card_creator">
                <div class="g-recaptcha" appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)"></div>
              </div>



              <div class="card_creator">
                <div class="redirectToLogin"
                  style="text-align: right;margin-top: 10px; margin-bottom: 10px; font-size: 12pt;">
                  <span class="redirect" routerLink="/forgot-password"><u>Saperlipopette, vous avez encore oublié votre
                      "mot qui passe" ?! </u></span>
                </div>
              </div>
            </div>
            <div class="card_creator">
              <button type="submit" class="btn btn-primary"
                style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px;"
                [disabled]="!Form.valid ">
                Connexion
                <span class="icon-iconConnexionNew" style="font-size: 1.3em;"></span>
              </button>
            </div>





          </form>
          <div class="redirectToLogin" style="margin-top: 10px; text-align:center;">
            <span>Vous n'avez pas encore de compte <span>
                <img src="./assets/images/LogoBosscool.png" width="90px" height="30px" />
              </span>? <span class="redirect" routerLink="/sign-up"> <br><u>Inscrivez-vous !</u></span></span>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
