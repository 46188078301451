import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../auth.service";
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../local-user';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  user:any
  localUserData: LocalUser;

  constructor(
    public authservice: AuthService,
    public router: Router,
  ) {  //console.log(Date() ,'constructor')
  }


  ngOnInit(): void {

    this.user=this.authservice.tempuser
 
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
  
    }


  }

  gotoSignIn(){
    this.router.navigate(['sign-in']);

  }

}