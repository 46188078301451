<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class=" border-bottom" style="text-align: center; margin-bottom: -30px; margin-top: 100px;">
    <h1 style="color:white;">Tronches-Binoscope</h1> 
  </div>


  

  <div  *ngIf="(localUserData.photoURL==null||localUserData.photoURL=='Einstein')&&!localUserData.isAdmin" class="wrapper" style="margin-top:35px">
    <div class="cards_wrap">
      <div class="card_item" style="margin-bottom: 20px;">
        <div class="card_inner">
          <div class="card_top center">
            <img src="assets/images/NoFavorites.png" width="60%" height="60%" class="nodata-msg" alt="">
          </div>
          <div class="card_bottom">
            <div class="card_info">
              <p>
                Et non, pas de chance ! Vous ne pourrez pas voir vos collègues tant que vous n'aurez pas montré votre 
                tronche.<br>
                Pour cela:
            
              <ul style="padding-left:25px;margin-top: -15px;">
                <li>
                  cliquez ici, sur l'icône <span routerLink="/profile" class="icon-iconProfil"style="font-size: 1.4em;"><span class="path1"></span><span class="path2"></span></span>
                
                </li>
                <li>
                  puis une fois dans votre profil, suivez la procédure pour changer de tronche.
                </li>
                
              </ul>
       
  
            </div>
            <div class="card_creator">
              <button type="button" class="btn btn-danger" routerLink="/travcols-list">
                Nan ! Je ne montrerai pas ma tronche !
                <span class="icon-iconEffacer"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div  
  *ngIf="(localUserData.photoURL!=null&&localUserData.photoURL!='Einstein')||(localUserData.photoURL!=null&&localUserData.isAdmin)" class="wrapper"  style="margin-top:35px">
 
  <ng-container *ngFor="let coll of colleagues;let i=index">

    <div style=" margin-left: auto;margin-right: auto;left: 0; right: 0;text-align: center;" 
      *ngIf="current == i">
  
    <div class="cards_wrap" >
      <div class="card_item" style="margin-bottom: 20px;">
        <div class="card_inner">

          <div class="card_top center">

            <button class="btn btn-primary" style="margin-right: 19px;" (click)="onLeft()">
              <
             </button>
            
                <img style=" height: 245px;  width: 245px;  border-radius: 50%; border: 5px solid white;
                  margin-left:  -2.5px;  margin-top:-2.5px;"[src]='coll.photoURL'> 
            <button class="btn btn-primary" style="margin-left: 19px;" (click)="onRight()">
              >
               
             </button>
          </div>
          <div class="card_bottom">
            <div class="card_info" >
              
              <div style="border-bottom:dashed var(--primaryNew) 1px;padding-bottom: 15px;" >
                {{coll.firstName}} {{coll.lastName}} 
              </div>            
  
  
  
              <div style="pointer-events: none;">
                <form [formGroup]="formTronchebinoscope">
 
                  <div  class="editor" style="border: none;"  >
                    
                    
                  
                    <ngx-editor [editor]="editor" formControlName="editorContent">
                    </ngx-editor>
                    
                  
                  </div>
                  
                </form>
    
                
              </div>
             
            </div>
            <div *ngIf="(!submitted)&&(isLoading)" style=" text-align:center ">
              Envoi du message... Merci de patienter.
              <br>
              <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">
  
  
            </div>
            <div *ngIf="submitted"
              style=" text-align:center ">
              {{responseMessage}}
            </div>
            <div class="card_creator">
              <button *ngIf="(localUserData.email!=coll.emailAddress)&&(!localUserData.isAdmin)&&(!submitted)&&(!isLoading)" type="button" class="btn btn-primary" style="margin-right: 19px;" (click)=" Signal(coll)" >
                Signaler
              
             <span class="icon-iconSignaler" style="font-size: 1.5em;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
            </button>
            <button type="button" class="btn btn-primary" style="margin-right: 19px;"  (click)=" onBack()">
              Retour
              <i class="icon-iconRetour" style="font-size: 1.3em;"></i>
             </button>
             <a *ngIf="coll">
             <button *ngIf="(localUserData.isAdmin)&&(coll.photoURL!='Einstein')" type="button" class="btn btn-danger" style="margin-right: 19px;" (click)="deletePicture()" >
              Supprimer Photo
              <span class="icon-iconSupprimer" style="font-size: 1.4em;" ><span
                class="path1"></span><span class="path2"></span></span>
             </button>

             <button *ngIf="(localUserData.isAdmin)&&(coll.autoDescriptive!='Pas d\'auto-descriptif')&&(coll.autoDescriptive!='<p></p>')" type="button" class="btn btn-danger" style="margin-right: 19px;" (click)="deleteText()" >
              Supprimer Texte
              <span class="icon-iconSupprimer" style="font-size: 1.4em;" ><span
                class="path1"></span><span class="path2"></span></span>
             </button>

             <a *ngIf="(localUserData.isAdmin)&&(coll.role=='1,5')">
              <button type="button" class="btn btn-primary" style="margin-right:25px;" (click)=" onNotBlock()">
                Débloquer
              </button>
              </a>

              <a *ngIf="(localUserData.isAdmin)&&(coll.role!='1,5')">
              <button type="button" class="btn btn-danger" style="margin-right:25px;" (click)=" onBlock()">
                Bloquer
              </button>
              </a>

            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  

</ng-container>
</div>


<ng-template #MailWhenDeletePhoto let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Envoi d'un email</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <a *ngIf="colleagues">
  <div *ngIf="(!submitted)&&(!isLoading)" class="modal-body" style="font-size: 13pt;">

    Vous venez d'effacer la photo de {{colleagueInfo.firstName}} {{colleagueInfo.lastName}},
    si vous voulez lui envoyer un message pour l'en informer , veuillez cliquer sur le
    bouton sur "Envoyer",
    sinon, veuillez cliquer sur "Fermer".
    <br>
    Merci

  </div>
  <div *ngIf="(!submitted)&&(isLoading)" class="modal-body" style="font-size: 13pt;">
    Envoi du message... Merci de patienter.
    <br>
    <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">

  </div>
  <div *ngIf="submitted" class="modal-body" style="font-size: 13pt;">
    <span>{{responseMessage}}</span><br>
    Merci

  </div>

  <div class="modal-footer">
    <button *ngIf="(!submitted)&&(!isLoading)" type="button" class="btn btn-danger"
      (click)="mailPhoto()">Envoyer</button>

    <button *ngIf="(!isLoading)" type="button" class="btn btn-danger"
      (click)="modal.close('Close Photo')">Fermer</button>
  </div>
  </a>
</ng-template>

<ng-template #MailWhenDeleteDescription let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Envoi d'un email</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div *ngIf="(!submitted)&&(!isLoading)" class="modal-body" style="font-size: 13pt;">

    Vous venez d'effacer le texte descriptif de {{colleagues[current].firstName}} {{colleagues[current].lastName}},
    si vous voulez lui envoyer un message pour l'en informer , veuillez cliquer sur le
    bouton sur "Envoyer",
    sinon, veuillez cliquer sur "Fermer".
    <br>
    Merci

  </div>
  <div *ngIf="(!submitted)&&(isLoading)" class="modal-body" style="font-size: 13pt;">
    Envoi du message... Merci de patienter.
    <br>
    <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">

  </div>
  <div *ngIf="submitted" class="modal-body" style="font-size: 13pt;">
    <span>{{responseMessage}}</span><br>
    Merci

  </div>

  <div class="modal-footer">
    <button *ngIf="(!submitted)&&(!isLoading)" type="button" class="btn btn-danger"
      (click)="mailDescription()">Envoyer</button>

    <button *ngIf="(!isLoading)" type="button" class="btn btn-danger"
      (click)="modal.close('Close Text')">Fermer</button>
  </div>
</ng-template>