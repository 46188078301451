

    <header class="page-header" id="header" >
      <app-header></app-header>
    </header>
    
    <div class="d-flex justify-content-center  align-items-center border-bottom" style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color:white;">Réinitialisation du mot de passe</h1>
</div>






<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">

        </div>
        <div class="card_bottom">
          <form [formGroup]="Form" (ngSubmit)="onSubmit()">
            <div class="card_info">
              Veuillez entrer votre adresse email professionnelle. Vous recevrez un mail vous permettant de réinitialiser votre mot de passe. <br>
              Pensez aussi à vérifier vos spams !


              <div style="display: flex;margin: auto;width: 100%;">
              
              <div style="flex-direction: row;margin: 0px;width: 100%;vertical-align: top;">
               
              <input type="text" id="emailLeft" autofocus formControlName="emailLeft" 
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailLeft.errors }" class="formControl left "
                style="margin-bottom: 10px;margin-top: 10px;width: 100%;margin-left: 0px;margin-right: 0px;" placeholder="Préfixe email"
                />
                <div *ngIf=" f.emailLeft.errors" class="invalid-feedback" style="width: 100%;margin: auto;">
                  <div *ngIf=" f.emailLeft.errors.required">
                    
                    Le préfixe de l'adresse mail est indispensable.
                  </div>
                  <div *ngIf="!f.emailLeft.pattern">
                    <!-- l'email n'est pas du bon type -->
                    Le préfixe de l'adresse mail doit être valide !
                  </div>
                </div>
                   
              </div>

              <span style="margin-top:15px"> @</span>
             
              <div style="flex-direction: row;margin: 0px;width: 100%;vertical-align: top;">

              <input type="text" id="emailRight"  formControlName="emailRight"
                [ngClass]="{ 'is-invalid': f.emailRight.touched && f.emailRight.errors }" class="formControl left "
                style="margin-bottom: 10px;margin-top: 10px;width: 100%;margin-left: 0px;margin-right: 0px;" placeholder="Suffixe email"
                />

                <div *ngIf=" f.emailRight.errors" class="invalid-feedback" style="max-width: 100%;margin: auto;">
                  <div *ngIf=" f.emailRight.errors.required">
                    Le suffixe de l'adresse mail est indispensable.
                  </div>
                  <div *ngIf="!f.emailRight.pattern">
                    <!-- l'email n'est pas du bon type -->
                    Le suffixe de l'adresse doit être valide !
                  </div>
                </div>
              
              </div>

              
            </div>


              
           
              

              <input type="email" formControlName="email2"
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailRight.touched &&  f.email2.errors }" class="formControl" hidden
                style="margin-bottom: 10px;margin-top: 10px;" />

              <input type="email" formControlName="emailConfirm"
                [ngClass]="{ 'is-invalid': f.emailLeft.touched && f.emailRight.touched &&  f.email2.errors }" class="formControl" hidden
                style="margin-bottom: 10px;margin-top: 10px;" />


              <div class="card_creator">
                <div class="g-recaptcha" appRecaptcha (recaptchaSuccess)="onRecaptchaSuccess($event)"></div>
              </div>



            </div>
            <div class="card_creator">
 
              <button type="submit" class="btn btn-primary"
              style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px;"
              [disabled]="!Form.valid ">
              Envoyer
                <span class="icon-iconVerifyEmail" style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
              
            </button>
            </div>





          </form>
          <div class="redirectToLogin" style="margin-top: 10px; text-align:center;">
            <span> Par contre, si vous avez un éclair de lucidité et que vous vous souvenez de votre "mot qui était
              passé", <span class="redirect" routerLink="/sign-in"> <u> cliquez ici.</u></span></span>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
