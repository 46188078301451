<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 15px;">
  <h1 style="color: white; margin-right: 25px;">Se réincarner dans un collègue (tant pis pour l'odeur)</h1>
  
</div>

<div class="pricing-header mx-auto">

  <!-- Preloader shows before the data loads-->
  <div class="no-data text-center" *ngIf="preLoader">
    <img src="assets/preloader.svg" class="preloader-icon" alt="">
  </div>



  <div class="wrapper" style="min-width: 100%;">
    <div class="cards_wrap" style="min-width: 100%;">
      <div class="card_item" style="min-width: 100%;margin-bottom: 20px;">
        <div class="card_inner" style="min-width: 100%;">
         
          <div class="card_top center" style="min-width: 100%;">
          </div>
          <div class="card_bottom" style="min-width: 100%;">
            <div class="card_info" style="min-width: 100%;">
              <!-- Showing allcolleagues data -->
              <div style="overflow-x:auto">

                <table scrollx="true" style="margin-left:auto;margin-right:auto;min-width: 100%;">

                  <tr>
                    
                    <th  class="center">
                      <span *ngIf="!this.viewSearch['firstName']"> Prénom </span> 

                      
                      <span *ngIf="!this.viewSearch['firstName']" (click)="Search('firstName')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input  *ngIf="this.viewSearch['firstName']" [(ngModel)]="searchTextFirstName"  class="search" placeholder="Chercher un prénom"style="margin:none">


                      <span  *ngIf="this.viewSearch['firstName']" (click)="EndSearch('firstName')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>

                    
                      
                    </th>


                    <th class="center">
                      <span *ngIf="!this.viewSearch['lastName']"> Nom </span> 
            
                      <span *ngIf="!this.viewSearch['lastName']" (click)="Search('lastName')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['lastName']" [(ngModel)]="searchTextLastName" class="search" placeholder="Chercher un nom">


                      <span  *ngIf="this.viewSearch['lastName']" (click)="EndSearch('lastName')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>
                    
                     
                    </th>

                    <th class="center">
                      <span *ngIf="!this.viewSearch['emailAddress']"> Email </span> 
                     
                      <span *ngIf="!this.viewSearch['emailAddress']" (click)="Search('emailAddress')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['emailAddress']" [(ngModel)]="searchTextEmailAddress" class="search" placeholder="Chercher un email">


                      <span  *ngIf="this.viewSearch['emailAddress']" (click)="EndSearch('emailAddress')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>

                    
                      

                    </th>

                    <th class="center">
                      <span *ngIf="!this.viewSearch['realRole']"> Rôle </span> 
                      

                      <span *ngIf="!this.viewSearch['realRole']" (click)="Search('realRole')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['realRole']" [(ngModel)]="searchTextRole" class="search"  placeholder="Chercher un rôle">


                      <span  *ngIf="this.viewSearch['realRole']" (click)="EndSearch('realRole')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>

                    
                    </th>

                    <th class="center">
                      
                      <span ngbTooltip="Quota" *ngIf="!this.viewSearch['quota']" class="icon-iconQuota"style="font-size: 1.6em;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>

                     

                      <span *ngIf="!this.viewSearch['quota']" (click)="Search('quota')">
                        <span ngbTooltip="Filtrer" class="icon-iconFiltreOk" style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
                      </span>

                      <input *ngIf="this.viewSearch['quota']" [(ngModel)]="searchTextQuota" class="search" placeholder="Chercher un quota">


                      <span  *ngIf="this.viewSearch['quota']" (click)="EndSearch('quota')">
                        <span ngbTooltip="Arrêter filtre" class="icon-iconFiltreKO"style="font-size: 1.4em;cursor: pointer;"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                      </span>

                    
                    </th>

                    
                  </tr>

                  <tbody *ngIf="hideWhenNoColleague">
                    <!-- *ngFor loop iterates over TravCol array and fetch the allcolleagues's data -->
                    <!-- paginate pipe will add pagination in allcolleagues's list, it won't show if items are less then 7 -->
                    <tr [ngStyle]="{'color':colleague.isFavorite ? 'var(--favorites)' : 'white' }" *ngFor="let colleague of sortBy(this.sort)|
                  

                      grdFilter: {firstName: searchTextFirstName} | 
                      grdFilter: {lastName: searchTextLastName} |
                      grdFilter: {emailAddress: searchTextEmailAddress} |
                      grdFilter: {realRole: searchTextRole} | 
                      grdFilter: {quota: searchTextQuota}|
                      paginate: { itemsPerPage: 10, currentPage: p };
                      let i = index;">
                     
                      <td >{{colleague.firstName}}</td>
                      <td>{{colleague.lastName}}</td>
                      <td (click)="Substitute(colleague)" style="cursor: pointer;color:var(--primaryNew)">{{colleague.emailAddress}}</td>
                      <td >{{colleague.realRole}}</td>
                      <td class="center">{{colleague.quota}}</td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card_creator" style="margin-top: 10px;">
                <!-- Pagination -->
                <pagination-controls class="my-pagination" (pageChange)="p = $event" autoHide="true" responsive="true"
                  previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>

