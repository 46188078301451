
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Validators, Editor, Toolbar } from 'ngx-editor';
import { ParametersService } from '../parameters.service';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { Location } from '@angular/common';


@Component({
  selector: 'app-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})





export class EditInfoComponent implements OnInit, OnDestroy {
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];


  form = new FormGroup({
    editorContent: new FormControl('', Validators.required()),
  });
  infoPage: string
  localUserData: LocalUser;;

  editMode: boolean =false



  constructor(

    public parametersApi: ParametersService,
    private location: Location,
  ) { //console.log(Date() ,'constructor')
   }





  

  ngOnInit(): void {
   // console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
      this.editor = new Editor();

      this.parametersApi.GetInfoPage().valueChanges().subscribe(data => {
        //console.log(Date() ,data)
        //this.infoPage=data
        this.form.controls['editorContent'].setValue(data)
  
        // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
      })
    }



  }


  get doc(): AbstractControl {
    return this.form.get("editorContent");
  }


  ngOnDestroy(): void {
    this.editor.destroy();
  }

  onSubmit() {
    //console.log(Date() ,this.form.controls['editorContent'].value.replaceAll("<p>","").replaceAll("</p>",""));
    //console.log(Date() ,this.doc)
    this.parametersApi.updateInfoPage(this.form.controls['editorContent'].value.replaceAll("<p>", "").replaceAll("</p>", ""))
  }

  toggleAction() {
    if (Number(this.localUserData.role) < 3 )   return 
    if (this.editMode) {
      this.parametersApi.updateInfoPage(this.form.controls['editorContent'].value)
      this.infoPage=this.form.controls['editorContent'].value
    this.editMode=false
  }
    else {

      this.editMode=true
    
  }
}

Cancel() {
 
  this.editMode=false

}

goBack() {
  this.location.back();
}

}