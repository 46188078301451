import { Injectable, NgZone } from '@angular/core';
import { LocalUser } from "./local-user";
import { AngularFireAuth } from "@angular/fire/auth";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import * as CryptoJS from 'crypto-js';





@Injectable({
  providedIn: 'root'
})

export class AuthService {


  allColleagueRef: AngularFireObject<any>;
  cpt: number = 1
  localUserData: LocalUser;
  public isLogged: boolean = false
  tempuser: any;

  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public toastr: ToastrService,
    private db: AngularFireDatabase,


  ) {

    //  console.log(Date() ,'constructor')

    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
    }

  }

  // Sign in with email/password
  SignIn(email, password) {

    this.SignOut
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        //console.log(result)
        if (result.user && result.user.emailVerified) {
          ///this.emailID = email.toLowerCase().replace(/([.])/g, '*');

          ///let s = this.db.object('colleagues/' + this.emailID);
          let s = this.db.object('colleagues/' + email.toLowerCase().replace(/([.])/g, '*'))
          let ref = s.valueChanges().subscribe(colleague => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
            if (colleague != null && colleague['emailAddress'].toLowerCase() == email.toLowerCase()) {
              //console.log(Date() ,result.user)

              this.SetUserData(result.user, colleague)
              this.toastr.success("Bienvenue et bon travail !", 'CONNEXION RÉUSSIE')
              //this.updateUserData('isLogged', true)


              this.router.navigate(['terms-of-use']);
            }
            else {
              this.toastr.error("Vous ne remplissez pas les conditions pour utiliser BossCool. Veuillez vous renseigner ... délinquant !", 'ADRESSE INTERDITE');
              this.router.navigate(['welcome']);
            }
            ref.unsubscribe();
          })
        }
        else {
          this.tempuser = result.user
          //this.SetMiniUserData(result.user)
          this.toastr.error("Avez-vous pensé à valider votre adresse email ?", 'UN OUBLI ?');
          this.router.navigate(['verify-email-address'])

        }
      })
      .catch((error) => {
        console.log(Date(), error);
        this.toastr.error("Mot de passe ou adresse email incorrect(s) ... ou vous n'êtes pas encore inscrit.", 'ÉCHEC DE CONNEXION')
        this.router.navigate(['welcome'])

      })
  }


  UpdateUserDisplayName(displayName, user) {
    //var user = this.afAuth.auth.currentUser;
    //console.log(Date() ,user);
    user.updateProfile({
      displayName: displayName,

    }).then(function () {
      // Update successful.
    }).catch(function (error) {
      console.log(Date(), error);
      // An error happened.
    });
  }
  UpdateCurrentUserPhotoUrl(photoURL) {
    var user = this.afAuth.auth.currentUser;
    //console.log(Date() ,'UpdateCurrentUserPhotoUrl')
    user.updateProfile({ photoURL: photoURL })
      .then(function () {
        //console.log(Date() ,user);
      })
      .catch(function (error) {
        console.log(Date(), error);
        // An error happened.
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {

    var emailID = email.toLowerCase().replace(/([.])/g, '*');
    return this.afAuth.auth.signInWithEmailAndPassword("connect@bosscool.be", "B0ssC00l@nonyme")
      //return this.afAuth.auth.signInAnonymously()
      .then((result) => {
        console.log(result)
        if (result.user && !result.user.emailVerified) {

          let s = this.db.object('colleagues/' + emailID);
          let ref = s.valueChanges().subscribe(colleague => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
            //console.log(Date() ,colleague);

            if (colleague != null && colleague['emailAddress'].toLowerCase() == email.toLowerCase()) {
              this.CreateUser(email, password)
            }
            else {
              this.toastr.error("Vous ne remplissez pas les conditions pour utiliser BossCool. Veuillez vous renseigner ... délinquant !", 'ADRESSE INTERDITE');
              this.router.navigate(['welcome']);
            }
            ref.unsubscribe();
          })
          //sigout sans nettoyage localstorage car non existant
        }
        else {
          // si on arrive ici , c'est que le user "connect" a été piraté
          this.toastr.error("Mmmh bizarre bizarre !", 'Bizarre');
          this.router.navigate(['welcome']);
          //sigout sans nettoyage localstorage car non existant

        }
        return this.afAuth.auth.signOut()
      })
      .catch(error => {
        this.toastr.error("La communication avec le serveur est mauvaise, veuillez recommencer. ", 'MAUVAISE COMMUNICATION');
        this.router.navigate(['welcome']);
        console.log(Date() ,error)
      });
  }


  CreateUser(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail(result.user);
      })
      .catch((error) => {
        console.log(Date(), error);
        this.toastr.error('Cette adresse email a déjà été enregistrée, veuillez vous connecter !', 'VINDJU !')
        this.router.navigate(['sign-in']);
      })
  }


  // Send email verfificaiton when new user sign up
  SendVerificationMail(user) {
    this.afAuth.auth.languageCode = 'fr';
    //console.log(user)
    return user.sendEmailVerification()
      .then(() => {
        this.router.navigate(['spam-message']);
        //this.toastr.success('Mail envoyé, ouvrez votre boîte mail afin de finaliser la procédure de vérification et connectez-vous. Pensez à vérifier vos courriers indésirables.', 'SUCCÈS');
      })
      .catch((error) => {
        console.log(Date(), error);
        this.toastr.error("Attention : Vous ne pouvez pas cliquer trop de fois en suivant sur ce bouton. Veuillez réessayer dans quelques minutes !", 'ERREUR')
        this.router.navigate(['welcome']);

      });
  }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user, colleague) {
    //    console.log(Date() ,'setuserData')
    this.localUserData = {
      uid: user.uid,
      email: user.email,
      emailID: user.email.replace(/([.])/g, '*'),
      displayName: colleague.firstName + ' ' + colleague.lastName,
      photoURL: colleague.photoURL,
      emailVerified: user.emailVerified,
      role: colleague.role,
      quota: colleague.quota,
      isAnonymous: user.isAnonymous,
      //      initials: colleague.lastName.replace(/ /g,"").substring(0, 4).toUpperCase()+ colleague.firstName.replace(/ /g,"").substring(0, 2).toUpperCase(),
      initials: colleague.lastName.replace(/ /g, "").substring(0, 4).toUpperCase() + colleague.firstName.replace(/ /g, "").substring(0, 2).toUpperCase(),
      shortName: colleague.firstName + ' ' + colleague.lastName.substring(0, 1).toUpperCase(),
      isLogged: true,
      isAdmin: (colleague.role >= 3) ? true : false,
      isInvite: (colleague.role == 1) ? true : false
    }

    localStorage.removeItem('userData')
    localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(this.localUserData).trim(), 'AMortLeCovid').toString());
    return
  }




  updateUserData(param, data) {
    this.localUserData[param] = data
    //console.log(Date() ,this.localUserData)
    localStorage.setItem('userData', CryptoJS.AES.encrypt(JSON.stringify(this.localUserData).trim(), 'AMortLeCovid').toString());
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    this.afAuth.auth.languageCode = 'fr';
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toastr.success('Mail envoyé, ouvrez votre boîte mail afin de finaliser la procédure de réinitialisation de mot de passe.', 'SUCCES');
      })
      .catch((error) => {
        console.log(Date(), error);
        this.toastr.error('Veuillez entrer la bonne adresse email. \n' + "Laissez-vous tenter à la tentation d'une nouvelle tentative.", 'ÉCHEC')
      })
  }


  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {


    var user = this.afAuth.auth.currentUser;

    if (user == null) {

      return false
    }
    else

      return (user.emailVerified !== false) ? true : false;

  }






  get isAdmin(): boolean {
    return (this.localUserData != null && this.localUserData.isLogged && this.localUserData.role >= 3) ? true : false;
  }


  get isInvite(): boolean {
    return (this.localUserData != null && this.localUserData.isLogged && this.localUserData.role == 1) ? true : false;
  }
  get isUserAnonymousLoggedIn(): boolean {
    return (this.localUserData !== null) ? this.localUserData.isAnonymous : false
  }

  get currentUserId(): string {
    return (this.localUserData !== null) ? this.localUserData.uid : '';
  }

  SignOut() {

    return this.afAuth.auth.signOut()
      .then(() => {
        if (localStorage.getItem('userData') !== null) {
          this.updateUserData('isLogged', false)
          this.isLogged = false
          //console.log(Date() ,'then: ')
          this.localUserData = null
          localStorage.removeItem('userData');
          //this.db.database.goOffline();
          this.toastr.success('Vous avez été déconnecté. À la prochaine !', 'DECONNEXION RÉUSSIE');
          this.router.navigate(['welcome'])
        }
      })
      .catch((error) => {
        console.log(Date(), error);
        //this.toastr.error("Mot de passe ou adresse email incorrect(s) ... ou vous n'êtes pas encore inscrit.", 'ÉCHEC DE CONNEXION')

      })
    //}
    this.router.navigate(['welcome'])
  }
}