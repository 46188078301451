import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent   {
  localUserData: any;
  env=environment.customer
  constructor(
    public router: Router,
  ) { // console.log(Date() ,'constructor')
  }

  ngOnInit(): void {
    // console.log(Date() ,'ngoninit')
 
     if (localStorage.getItem('userData') !== null ) {
       this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      if (this.localUserData.isLogged){
        this.router.navigate(['terms-of-use']);

      }

     }
     
 
     }

}

