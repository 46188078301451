import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParametersService } from '../../others/parameters.service';
import { AuthService } from "../auth.service";
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.dev';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  token: string;
  registerForm: FormGroup;
  suffix: any;
  localUserData: any;
  

  constructor(
    public authservice: AuthService,
    //public parametersApi: ParametersService,
    private formBuilder: FormBuilder,
    private router: Router, 
  ){  //console.log(Date() ,'constructor')
  }


  ngOnInit(): void {
    //console.log(Date() ,'ngoninit')

    if (localStorage.getItem('userData') !== null ) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
     if (this.localUserData.isLogged){
       this.router.navigate(['terms-of-use']);
     }
    }

    this.registerForm = this.formBuilder.group(
      {
        emailLeft:  ["", [Validators.required]],
        emailRight:  ["", [Validators.required, Validators.pattern('^[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],        password: ["", [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-/.€<>µ£~§={}]).{8,}$')]],
        //mdp min 8 caracteres + 1 MAJ + 1 caract special
        confirmPassword: ["", [Validators.required]],
        email2:["", [Validators.required,Validators.pattern('ReCaptchaChecked')]],
        emailConfirm : [[]]
       
      },

      {
        validator: this.MustMatch("password", "confirmPassword")
      }
      
      
    );
   // this.parametersApi.GetSuffix().valueChanges().subscribe(data => {

   this.registerForm.controls['emailRight'].setValue(environment.suffix)
    //})
    
  }

  /* permet de lire les champs*/
  get f() {
    
    return this.registerForm.controls;
    
  }
 
  onRecaptchaSuccess(token: string) {
    
    this.registerForm.controls['email2'].setValue('ReCaptchaChecked');
    //console.log(Date() ,this.f.emailConfirm.value)
    if (this.f.emailConfirm.value!=""){
     this.registerForm.controls['confirmPassword'].setValue("")
     token=""
    }
    //console.log(Date() ,token)
    this.token = token;
    //console.log(Date() ,this.token)
    
    
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

onSubmit() {

  this.authservice.SignUp(this.f.emailLeft.value+"@"+this.f.emailRight.value, this.f.password.value)
}

}





