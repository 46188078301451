<header class="page-header" id="header" >
  <app-header></app-header>
</header>

<div class="d-flex justify-content-center  align-items-center border-bottom" style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color:white;">Vérification d'email</h1>
</div>




<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">

        </div>
        <div class="card_bottom">
          <div class="formGroup" *ngIf="this.localUserData as user">
          <p class="text-center">Nous avons envoyé un email de vérification à l'adresse <strong> {{user.email}}</strong>.</p>
        </div>
          <div class="card_info">
            <p class="text-center">Vérifiez votre boîte mail et cliquez sur le lien présent dans votre mail afin d'activer votre compte.
              Attention l'email pourrait se trouver dans vos "spasmes" </p>
            <div class="card_creator">

             
                <button type="button" class="btn btn-primary"  (click)="this.authservice.SendVerificationMail(user)">
                
                  Renvoyer un email de vérification
                  <span class="icon-iconVerifyEmail" style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
                </button>
              

            </div>
          </div>
          <div class="redirectToLogin" style="margin-top: 10px; text-align:center;">
            <span>  <span class="redirect"(click)="gotoSignIn()"> <u> Connexion</u></span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!--
<div class="displayTable">
    <div class="displayTableCell">
  
      <div class="authBlock">
        <h3>Thank You for Registering</h3>
  
        <div class="formGroup" *ngIf="this.localUserData as user">
          <p class="text-center">We have sent a confirmation email to <strong>{{user.email}}</strong>.</p>
          <p class="text-center">Please check your email and click on the link to verfiy your email address.</p>
        </div>
        
       
        <div class="formGroup">
          <button type="button" class="btn btnPrimary" (click)="this.authservice.SendVerificationMail()">
            <i class="fas fa-redo-alt"></i>
            Resend Verification Email
          </button>
        </div>
  
      </div>
  
      <div class="redirectToLogin">
        <span>Go back to?<span class="redirect" routerLink="/sign-in"> Sign in</span></span>
      </div>
  
    </div>
  </div>-->