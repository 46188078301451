import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDismissReasons, NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LocalUser } from '../../boilerplate/local-user';
import { ColleaguesService } from '../../colleagues/colleagues.service';
import { ParametersService } from '../parameters.service';
import * as CryptoJS from 'crypto-js';
import { Colleague } from '../../colleagues/colleague';
import { trigger, transition, animate, style } from '@angular/animations'
import { Location } from '@angular/common';
import { Editor, Validators } from 'ngx-editor';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-tronchesbinoscope',
  templateUrl: './tronchesbinoscope.component.html',
  styleUrls: ['./tronchesbinoscope.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers:[NgbCarouselConfig],
  animations: [
    trigger('fade', [
      transition('void => *', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
    ])
  ]
})


export class TronchesbinoscopeComponent {
  @ViewChild('MailWhenDeletePhoto') photoModal: ElementRef;
  @ViewChild('MailWhenDeleteDescription') descriptionModal: ElementRef;
  localUserData: LocalUser;
  public colleagues: Colleague[];
  current = 0;
  userEmailID: any;
  realRole: any;
  moderatorEmailAddress:string;
  editor: Editor;
  formTronchebinoscope = new FormGroup({
    editorContent: new FormControl('', Validators.required()),
  });

  editMode: boolean = false
  isLoading = false; // sending the post request async so it's in progress
  submitted = false; // hide the response message on multiple submits
  responseMessage: string; // the response message to show to the user
  closeModal: string;
  colleagueInfo: any;
  enableRoleField: boolean;

  constructor(
    public colleaguesApi: ColleaguesService,
    public parametersApi: ParametersService,
    private location: Location,
    private http: HttpClient,
    private modalService: NgbModal,

  ) { //console.log(Date(), 'constructor')
  }

  ngOnInit() {


    // console.log(Date() ,'ngoninit')



    /*setInterval(() => {
      this.current = ++this.current % this.colleagues.length;
    }, 4000);
  */


    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      this.editor = new Editor();
    }

    let s = this.colleaguesApi.GetColleaguesList();
    s.snapshotChanges().subscribe(data => { // Using snapshotChanges() method to retrieve list of data along with metadata(key)
      this.colleagues = [];

      data.forEach(item => {
        let a = item.payload.toJSON();
        // console.log(Date() ,a)
        a['colleagueEmailID'] = item.key;
        //a['userEmailID']= this.userEmailID
        //item.payload.hasChild('isFavoriteOf/' + this.userEmailID) ? a['isFavorite'] = true : a['isFavorite'] = false
        //a['realRole'] = this.realRole[a['role']]
        //a['photoURL']=this.localUserData.photoURL
       
        
        if (a['photoURL'] != 'Einstein' && a['photoURL'] != null) {
         
         
          this.colleagues.push(a as Colleague);
    
         /* console.log(this.colleagues[this.current].role)
          if(this.colleagues[this.current].role!=1,5){
            this.enableRoleField=true;
          }
          else{
            this.enableRoleField=false;
          }*/

          this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
            this.moderatorEmailAddress = data.payload.toJSON()['moderatorEmailAddress']

          })

        }

      })
      if (this.colleagues[0].autoDescriptive) {
        this.formTronchebinoscope.controls['editorContent'].setValue(this.colleagues[0].autoDescriptive)
      }
      else {
        this.formTronchebinoscope.controls['editorContent'].setValue("Pas d'auto-descriptif")
      }
      //console.log(this.colleagues)
    })

  }

  onRight() {
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button
    this.current = this.current + 1
    if (this.current > this.colleagues.length - 1) {
      this.current = 0
    }
    if (this.colleagues[this.current].autoDescriptive) {
      this.formTronchebinoscope.controls['editorContent'].setValue(this.colleagues[this.current].autoDescriptive)
    }
    else {
      this.formTronchebinoscope.controls['editorContent'].setValue("Pas d'auto-descriptif")
    }



    //console.log(this.current)
  }


  onLeft() {
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button
    this.current = this.current - 1
    if (this.current < 0) {
      this.current = this.colleagues.length - 1
    }
    if (this.colleagues[this.current].autoDescriptive) {
      this.formTronchebinoscope.controls['editorContent'].setValue(this.colleagues[this.current].autoDescriptive)
    }
    else {
      this.formTronchebinoscope.controls['editorContent'].setValue("Pas d'auto-descriptif")
    }
    




    // console.log(this.current)
  }

  onBack() {
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button
    this.location.back();
  }

  //Signal(coll) {
  //  window.location.href = "mailto:" + this.moderatorEmailAddress + "?subject=Photo de profil inappropriée&body=La photo de profil de " + coll.firstName + " " + coll.lastName + " semble inapropriée. %0A " + this.localUserData.displayName
  //}

  Signal(coll) {

    //console.log(this.colleagueInfo)
    this.formTronchebinoscope.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Photo de profil inappropriée");
    formData.append("firstName", coll.firstName);
    formData.append("lastName", coll.lastName);
    formData.append("emailAddress", this.moderatorEmailAddress);
    //ilAddress+"?subject=Photo de profil inappropriée&body=. %0A " +this.localUserData.displayName


    let body =
      "Bonjour,\n" +
      "\n" +
      "La photo de profil de " + coll.firstName + " " + coll.lastName + " a été signalée par " + this.localUserData.displayName + "\n" +
      "\n" +
      `Si vous le désirez, vous pouvez donc vous connecter à BossCool et supprimer sa tronche.\n` +

      "\u000A" +

      "Votre équipe BossCool" + "\u000A" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "L'utilisateur a bien été signalé au modérateur'";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.formTronchebinoscope.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.formTronchebinoscope.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }



  deletePicture() {
    if (Number(this.localUserData.role) < 3) return
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button
    const id = this.colleagues[this.current].colleagueEmailID;
    //console.log(this.colleagues[this.current])
    this.colleagueInfo=this.colleagues[this.current]
    this.triggerModal(this.photoModal);
    this.colleaguesApi.UpdateCurrentColleaguePhotoURL(id, 'Einstein');
    

  }



  deleteText() {

    if (Number(this.localUserData.role) < 3) return
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button
    const id = this.colleagues[this.current].colleagueEmailID;
    this.triggerModal(this.descriptionModal);
    //console.log(id)
    this.colleaguesApi.UpdateCurrentColleagueAutoDescriptive(id, "Pas d'auto-descriptif");

  }
  triggerModal(content) {
    //console.log(content)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
        console.log(this.closeModal)
        if (res=="Close Photo"){
          window.location.reload()
        }
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    //console.log(reason)
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  mailPhoto() {
    //console.log(this.colleague)
   // this.formTronchebinoscope.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Photo de profil supprimée");
    formData.append("firstName", this.colleagueInfo.firstName);
    formData.append("lastName", this.colleagueInfo.lastName);
    formData.append("emailAddress", this.colleagueInfo.emailAddress);


    let body =
      "Bonjour,\n" +
      "\n" +
      "Le modérateur de BossCool vient d'effacer votre photo de profil car elle n'est pas adaptée.\n" +
      "\n" +
      `Veuillez en recréer une qui montre bien votre visage. \n` +
      "\u000A" +
      "Merci." + "\u000A" +
      "Votre équipe BossCool" + "\u000A" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "Le message a bien été envoyé au délinquant.";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.formTronchebinoscope.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.formTronchebinoscope.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }
  mailDescription() {
    //console.log(this.colleague)
    //this.formTronchebinoscope.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Descriptif de profil supprimé");
    formData.append("firstName", this.colleagues[this.current].firstName);
    formData.append("lastName", this.colleagues[this.current].lastName);
    formData.append("emailAddress", this.colleagues[this.current].emailAddress);


    let body =
      "Bonjour,\n" +
      "\n" +
      "Le modérateur de BossCool vient d'effacer votre descritpif de profil car il n'est pas adapté.\n" +
      "\n" +
      `Veuillez en recréer un plus correct. \n` +
      "\u000A" +
      "Merci." + "\u000A" +
      "Votre équipe BossCool" + "\u000A" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "Le message a bien été envoyé au délinquant.";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.formTronchebinoscope.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.formTronchebinoscope.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }

  onBlock(){
  //console.log(this.colleagues[this.current].colleagueEmailID)
 
    this.colleaguesApi.UpdateCurrentColleagueRole(this.colleagues[this.current].colleagueEmailID, "1,5");

  }


  onNotBlock() {
    //console.log(this.colleagues[this.current].colleagueEmailID)
    this.colleaguesApi.UpdateCurrentColleagueRole(this.colleagues[this.current].colleagueEmailID, "2");
  }
}