import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Reactive Form
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// NGX Pagination
import { NgxPaginationModule } from 'ngx-pagination';


// App routing modules
import { AppRoutingModule } from './app-routing.module';

// App components
import { AppComponent } from './app.component';
import { SignInComponent } from './components/boilerplate/sign-in/sign-in.component';
import { SignUpComponent } from './components/boilerplate/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/boilerplate/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/boilerplate/verify-email/verify-email.component';

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment.dev';

// Auth service
import { AuthService } from "./components/boilerplate/auth.service";

// Import below modules for NGX Toastr
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

//Components
import { AddStudentComponent } from './components/students/add-student/add-student.component';
import { HeaderComponent } from './components/others/header/header.component';
import { ProfileComponent } from './components/boilerplate/profile/profile.component';
import { FooterComponent } from './components/others/footer/footer.component';
import { StudentsListComponent } from './components/students/students-list/students-list.component';
import { EditStudentComponent } from './components/students/edit-student/edit-student.component';
import { AddTravColComponent } from './components/travcols/add-travcol/add-travcol.component';
import { EditTravColComponent } from './components/travcols/edit-travcol/edit-travcol.component';
import { TravColsListComponent } from './components/travcols/travcols-list/travcols-list.component';
import { WelcomeComponent } from './components/others/welcome/welcome.component';
import { ColleaguesListComponent } from './components/colleagues/colleagues-list/colleagues-list.component';
import { EditColleagueComponent } from './components/colleagues/edit-colleague/edit-colleague.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GreetingsComponent } from './components/others/greetings/greetings.component';
import { TravColsPrintComponent } from './components/travcols/travcols-print/travcols-print.component';

import { ProgressbarLabelsComponent } from './components/others/progressbar-labels/progressbar-labels.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaDirective } from './recaptcha.directive';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ImageCropperModule } from "ngx-image-cropper";
import {WebcamModule} from 'ngx-webcam';
import { AddColleagueComponent } from './components/colleagues/add-colleague/add-colleague.component';
import { GrdFilterPipe } from './grd-filter.pipe';
import { ParametersComponent } from './components/others/parameters/parameters.component';
import { TravcolsToBeValidatedComponent } from './components/Travcols/travcols-to-be-validated/travcols-to-be-validated.component';
import { InviteColleaguesComponent } from './components/colleagues/invite-colleagues/invite-colleagues.component';
import { TronchesComponent } from './components/others/tronches/tronches.component';
import { EditInfoComponent } from './components/others/edit-info/edit-info.component';
import { NgxEditorModule } from 'ngx-editor';
import { AutologoffComponent } from './components/others/autologoff/autologoff.component';
import { CountdownModule } from 'ngx-countdown';
import { TronchesbinoscopeComponent } from './components/others/tronchesbinoscope/tronchesbinoscope.component';
import { UrlService } from './components/others/url.service';
import {SpamMessageComponent } from './components/boilerplate/spam-message/spam-message.component';
import { SubstituteComponent } from './components/others/substitute/substitute.component';
import { AllTravcolsComponent } from './components/travcols/all-travcols/all-travcols.component';
import { HttpClientModule } from '@angular/common/http';
import { TermsOfUseComponent } from './components/others/terms-of-use/terms-of-use.component';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    AddStudentComponent,
    HeaderComponent,
    ProfileComponent,
    FooterComponent,
    StudentsListComponent,
    EditStudentComponent,
    AddTravColComponent,
    EditTravColComponent,
    TravColsListComponent,
    WelcomeComponent,
    ColleaguesListComponent,
    EditColleagueComponent,
    GreetingsComponent,
    RecaptchaDirective,
    TravColsPrintComponent,
    ProgressbarLabelsComponent,
    AddColleagueComponent,
    GrdFilterPipe,
    ParametersComponent,
    TravcolsToBeValidatedComponent,
    InviteColleaguesComponent,
    TronchesComponent,
    EditInfoComponent,
    AutologoffComponent,
    TronchesbinoscopeComponent,
    SpamMessageComponent,
    SubstituteComponent,
    AllTravcolsComponent,
    TermsOfUseComponent,
  ],

  imports: [
    BrowserModule,
    CountdownModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    WebcamModule,
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    ImageCropperModule,
    FormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({      // Register NgxToast NPM module
      timeOut: 10000,
      positionClass: 'toast-bottom-full-width', //top-right
      preventDuplicates: true,
      closeButton : true,
    }),
  NgxPaginationModule,
  NgMultiSelectDropDownModule.forRoot(),
  NgbModule,
  NgxEditorModule.forRoot({
    locals: {
      // menu
      bold: 'Gras',
      italic: 'Italique',
      code: 'Code',
      blockquote: 'Blockquote',
      underline: 'Souligné',
      strike: 'Barré',
      bullet_list: 'Puces',
      ordered_list: 'Numéros',
      heading: 'Titres',
      h1: 'Titre 1',
      h2: 'Titre 2',
      h3: 'Titre 3',
      h4: 'Titre 4',
      h5: 'Titre 5',
      h6: 'Titre 6',
      align_left: 'Gauche',
      align_center: 'Centré',
      align_right: 'Droite',
      align_justify: 'Justifié',
      text_color: 'Couleur Texte',
      background_color: 'Couleur Fond',
      insertLink: 'Insérer un lien',
      removeLink: 'Enlever un lien',
      insertImage: 'Insérer une image',

      // popups, forms, others...
      url: 'URL',
      text: 'Texte',
      openInNewTab: 'Ouvrir dans un nouvel onglet',
      insert: 'Insérer',
      altText: 'Texte de remplacement',
      title: 'Titre',
      remove: 'Effacer',
    },
  }),
  HttpClientModule,
  
 

  ],
  providers: [AuthService, AngularFirestore,UrlService],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(
    
    
    ) { // console.log(Date() ,'constructor')
    
 }



 }