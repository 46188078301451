import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ParametersService } from '../../others/parameters.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { ColleaguesService } from '../colleagues.service';


//import { this.colleagueApi.localUserData } from 'src/app/app.component';
@Component({
  selector: 'app-invite-colleagues',
  templateUrl: './invite-colleagues.component.html',
  styleUrls: ['./invite-colleagues.component.css']
})
export class InviteColleaguesComponent implements OnInit {

  public inviteColleagueForm: FormGroup;
  localUserData: LocalUser; suffix: any;
  moderatorEmailAddress: string;
  closeModal: string;
  submitted: boolean = false; // show and hide the success message
  isLoading: boolean = false; // disable the submit button if we're loading
  responseMessage: string; // the response message to show to the user
  ;

  constructor(
    public fb: FormBuilder,       // Form Builder service for Reactive forms
    public toastr: ToastrService,  // Toastr service for alert message
    private router: Router,
    public parametersApi: ParametersService,
    private modalService: NgbModal,
    private location: Location,
    private http: HttpClient,
    private colleaguesApi: ColleaguesService,
  ) { // console.log(Date() ,'constructor')
  }


  ngOnInit(): void {
    //console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)

    }
    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.moderatorEmailAddress = data.payload.toJSON()['moderatorEmailAddress']
      //this.suffix = data.payload.toJSON()['emailSuffix']

    })
    this.inviteCollForm()
  }


  // Go back to previous component

  onBack() {
    this.router.navigate(['colleagues-list']);

  }



  inviteCollForm() {
    this.inviteColleagueForm = this.fb.group({
      emailAddress: ["", [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-zA-Z\-0-9]+[\.]+[a-zA-Z]{2,}$')]],
      firstName: [''],
      lastName: [''],
      magicProtection: [''],

    })
  }

  get emailAddress() {
    return this.inviteColleagueForm.get('emailAddress');
  }

  get firstName() {
    return this.inviteColleagueForm.get('firstName');
  }

  get lastName() {
    return this.inviteColleagueForm.get('lastName');
  }
  get magicProtection() {
    return this.inviteColleagueForm.get('magicProtection');
  }



  ResetForm() {
    this.inviteColleagueForm.reset();
  }





  submitColleagueData() {
  
    const modifiedGuestEmail = this.inviteColleagueForm.controls['emailAddress'].value.toLowerCase().replace(/([.])/g, '*')
    this.colleaguesApi.GetColleague(modifiedGuestEmail).valueChanges().subscribe(data => {
        
    if (data!=null) {

      this.toastr.error("Vous ne pouvez pas inviter quelqu'un qui utilise déjà l'application"); // Show success message when data is successfully submited
      this.inviteColleagueForm.controls['emailAddress'].setValue('')
    }
    else if (this.inviteColleagueForm.status == "VALID" && this.magicProtection.value == "") {
      this.inviteColleagueForm.disable(); // disable the form if it's valid to disable multiple submissions
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits

      var formData: any = new FormData();
      formData.append("subject", "BossCool - Demande d'invitation d'un collègue");
      formData.append("firstName", this.inviteColleagueForm.get("firstName").value);
      formData.append("lastName", this.inviteColleagueForm.get("lastName").value);
      formData.append("guestEmail", this.inviteColleagueForm.get("emailAddress").value);
      formData.append("emailAddress", this.moderatorEmailAddress);
      //formData.append("emailAddress", "prst@hotmail.com");
 

      let body = 
        "Bonjour "+ environment.customer + ",\n"+
        "\n"+
        this.localUserData.displayName+" ("+ this.localUserData.email +") voudrait ajouter un invité.\n"+
        "\n"+
        "Si vous êtes d'accord, de façon à ce que cet invité puisse utiliser votre BossCool,\n"+
        "pourriez-vous ajouter l'utilsateur suivant dans la liste des collègues\n"+ 
        ' (Veillez à bien configurer son rôle comme "Invité"):\n'+
        "\n"+
        "Prénom : " + this.inviteColleagueForm.controls['firstName'].value + "\u000A"+
        "Nom : " + this.inviteColleagueForm.controls['lastName'].value +"\u000A"+
        "Adresse Email : " + this.inviteColleagueForm.controls['emailAddress'].value + "\u000A"+
        "Rôle : Invité\n"+
        "\u000A"+
        "Bonne journée"+"\u000A"+
        "Merci\n"+
        "Votre équipe BossCool"+"\u000A"+
        "... mais ne malmenez pas trop Expresso l'escargot\n"

      formData.append("message", body);

      this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
        (response) => {
          // choose the response message
          if (response["result"] == "success") {
            this.responseMessage = "Le message a bien été envoyé au modérateur. Merci";
          } else {
            this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer ! Merci";
          }
          this.inviteColleagueForm.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(response);
        },
        (error) => {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer ! Merci";
          this.inviteColleagueForm.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(error);
        }
      );
    }
  })
    //this.ResetForm();
    //this.onBack
  };

  cancel() {
    this.ResetForm();  // Reset form when clicked on reset button
    this.onBack
  }

  triggerModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  goBack() {
    this.location.back();
  }

}