<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class=" border-bottom" style="text-align: center; margin-top: 100px; margin-bottom: 15px;">
  <h1 style="color:white;">Ajouter un collègue</h1>
</div>




<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div style="text-align: right;margin-bottom: -20px;">
          <button ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataAddColleagues)">
            <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
          </button>
        </div>
        <div class="card_top center">

        </div>
        <div class="card_bottom">
          <form [formGroup]="ColleagueForm" (ngSubmit)="submitColleagueData()" novalidate>

            <div class="card_info">

              <label>Prénom</label>
              <input type="text" formControlName="firstName" style="text-transform:capitalize;" class="formControl left"
                required>
              <p *ngIf="firstName.touched && firstName.invalid" class="error"><sup>*</sup>Veuillez entrer un prénom</p>

              <label>Nom</label>
              <input type="text" style="text-transform: uppercase;" formControlName="lastName" class="formControl left"
                required>
              <p *ngIf="lastName.touched && lastName.invalid" class="error"><sup>*</sup>Veuillez entrer un nom</p>

              <label>Email</label>
              <input type="text" id="emailAddress" formControlName="emailAddress" class="formControl left" required>
              <p *ngIf="emailAddress.touched && emailAddress.invalid" class="error"><sup>*</sup>Veuillez entrer une
                adresse email</p>
              <p *ngIf="emailAddress.errors?.pattern" class="error"><sup>*</sup>Voyons, ceci n'est pas une adresse email
                !</p>
              <label>Rôle</label>
              <ng-multiselect-dropdown class="formControl left custom-font-size" required
                [placeholder]="'Choisissez un rôle'" [settings]="dropdownSettings" [data]="realRoleList"
                formControlName="role" searchPlaceholderText='Entrez un rôle' [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)">
              </ng-multiselect-dropdown>

              <div *ngIf="enabledQuota">
                <label>Quota</label>
                <input type="text" formControlName="quota" class="formControl left" required>
                <p *ngIf="quota.touched && quota.invalid" class="error"><sup>*</sup>Veuillez entrer un quota</p>
                <p *ngIf="quota.errors?.pattern" class="error"><sup>*</sup>Utilisez uniquement des chiffres</p>
              </div>
            </div>
            <div class="card_creator">
              <button type="button" class="btn btn-danger "
                style="border-radius:10px;padding: 10px;margin-top: 10px;margin-right: 30px;margin-bottom: 10px;"
                (click)="ResetForm()">Effacer
                <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>
              <button type="submit" class="btn btn-primary"
                style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px;"
                [disabled]="!ColleagueForm.valid">
                Valider
                <span class="icon-iconValider" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #modalDataAddColleagues let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Besoin d'aide? Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;">

    TEXTE A FAIRE
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>

<ng-template #MailNewColleague let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Envoi d'un email au nouveau collègue</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div *ngIf="(!submitted)&&(!isLoading)" class="modal-body" style="font-size: 13pt;">

    Vous venez de créer un nouveau collègue dont l'adresse email est {{emailNewColleague}},
    si vous voulez lui envoyer un message pour le prévenir qu'il peut utiliser l'application, veuillez cliquer sur le
    bouton sur "Envoyer",
    sinon, veuillez cliquer sur "Fermer".
    <br>
    Merci

  </div>
  <div *ngIf="(!submitted)&&(isLoading)" class="modal-body" style="font-size: 13pt;">
    Envoi du message... Merci de patienter.
    <br>
    <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">

  </div>
  <div *ngIf="submitted" class="modal-body" style="font-size: 13pt;">
    <span>{{responseMessage}}</span><br>
    Merci

  </div>

  <div class="modal-footer">
    <button *ngIf="(!submitted)&&(!isLoading)" type="button" class="btn btn-danger"
      (click)="submitMailNewColleagueData()">Envoyer</button>

    <button *ngIf="(!isLoading)" type="button" class="btn btn-danger" (click)="modal.close('Save click')">Fermer</button>
  </div>
</ng-template>