
<div class="wrapper">
    <!--header class="page-header" id="header" >
      <app-header></app-header>
    </header-->
    <main class="page-main">
      <router-outlet></router-outlet>
    </main>
    
    <footer class="page-footer" id="footer" style="z-index: 4;">
      <app-footer ></app-footer>
    </footer>
  </div>