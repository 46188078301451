<div class="center" style="color: white;margin-top: 100px;">
  <a href="http://www.bosscool.be" style="cursor:pointer">
    <img src="./assets/images/LogoBosscoolTitre.png" width="180px" height="60px" />
  </a>

  <br>
  <h2><strong>Conditions générales d'utilisation</strong></h2>
</div>



<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">

        <div class="card_bottom">
          <!--<div class="card_category">
              Inscription
            </div>-->
          <div class="card_info" style="text-align: center;justify-content: center;">

            <label class="toggle-switch" style="margin-right: 10px;">
              <input type="checkbox" [(ngModel)]="myProperty">
              <div class="slider"></div>

            </label>
            <div style="margin-bottom: 10px;">
              <p *ngIf="myProperty">J'accepte les </p>
              <p *ngIf="!myProperty">Je n'accepte pas les </p>
            </div>
            <span style="cursor: pointer; text-decoration:underline" (click)="openCGU(modalCGU)">Conditions
              d'Utilisation </span>

          </div>
          <div class="card_creator">

            <button *ngIf="myProperty"  type="button" class="btn btn-primary" routerLink="/profile">
              Continuer
              <span class="icon-iconAccepterCGU" style="font-size: 1.3em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>

            <button *ngIf="!myProperty" type="button" class="btn btn-primary" (click)="authservice.SignOut();">
              Continuer
              <span class="icon-iconDeconnexion" style="font-size: 1.3em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <ng-template #modalCGU let-modal>

    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Conditions générales d'utilisation</h4>
        <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="font-size: 13pt;">

        <h1 class="colorTitle">Préambule</h1>

        <p>Les présentes conditions générales d'utilisation sont conclues entre :

        <ul class="paddingLeft35">
          <li>l'éditeur de l'application, ci-après désigné "l’Éditeur";</li>

          <li>toute personne souhaitant utiliser l'application et ses services, ci-après appelé
            "l’Enseignant".</li>
        </ul>

        <h1 class="colorTitle">Article 1 - Principes</h1>

        <p>Les présentes conditions générales d'utilisation ont pour objet l'encadrement juridique de
          l’utilisation
          de l'application "BossCool" et de ses services.

        <p>Les conditions générales d'utilisation doivent être acceptées par tout Enseignant, l'accès à
          l'application vaut acceptation de ces conditions.


        <h1 class="colorTitle">Article 2- Evolution et durée des CGU</h1>

        <p>Les présentes conditions générales d'utilisation sont conclues pour une durée indéterminée.Le contrat
          produit ses effets à l'égard de l'Enseignant à compter du début de l’utilisation du service.

        <p>L’Éditeur se réserve le droit de modifier les clauses de ces conditions générales d’utilisation à
          tout
          moment et sans justification.

        <h1 class="colorTitle">Article 3 - Accès au site</h1>

        <p>Tout Enseignant autorisé par l'Editeur et ayant accès à internet peut accéder suivant des conditions
          choisies par l'Editeur et depuis
          n’importe où à l'application. Les frais supportés par l’Enseignant pour y accéder (connexion
          internet,
          matériel informatique, etc.) ne sont pas à la charge de l’Éditeur.

        <p>L'application et ses différents services peuvent être interrompus ou suspendus par l’Éditeur,
          notamment à
          l’occasion d’une maintenance, sans obligation de préavis ou de justification.


        <p> Ces Enseignants pourront accéder à "BossCool" en utilisant leurs identifiants de connexion.


        <h1 class="colorTitle">Article 4 - Responsabilités</h1>

        <p>La responsabilité de l'Éditeur ne peut être engagée en cas de défaillance, banissement, panne,
          difficulté ou
          interruption de fonctionnement, empêchant l'accès à l'application ou à une de ses fonctionnalités.

        <p>Le matériel de connexion à l'application est sous l'entière responsabilité de l'Enseignant qui doit
          prendre toutes les mesures appropriées pour protéger le matériel et les données notamment d'attaques
          virales par
          Internet. L'Enseignant est par ailleurs le seul responsable des sites et données qu'il consulte.


        <p>L'Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à l'encontre de
          l'Enseignant :

        <ul class="paddingLeft35">
          <li>du fait de l'usage du site ou de tout service accessible via Internet;</li>
          <li>du fait du non-respect par l'Enseignant des présentes conditions générales.</li>
        </ul>


        <p>L'Éditeur n'est pas responsable des dommages causés à l'Enseignant, à des tiers et/ou à l'équipement
          de
          l'Enseignant du fait de sa connexion ou de son utilisation et l'Enseignant renonce à toute action
          contre
          l'Éditeur de ce fait.

        <p>Si l'Éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de l'utilisation
          de
          l'application par l'Enseignant, l'Éditeur pourra retourner contre l'Enseignant pour obtenir
          indemnisation de tous les
          préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.


        <p> Il est permis à l'Enseignant de publier avec l'application "BossCool":

        <ul class="paddingLeft35">
          <li>son profil contenant son quota de travail collaboratif, sa photo et un texte explicatif;</li>
          <li>des travaux collaboratifs;</li>
        </ul>

        <p> L'Enseignant qui utilise la partie "tronchebinoscope" s’engage à insérer une photo style "photo de
          carte d'identité" et accepte que ces
          publications soient modérées ou refusées par l’Éditeur, ou signalées par un autre utilisateur, sans
          obligation de justification.

        <p> En utilisant sur le site, l’Enseignant s'engage à respecter les règles en vigueur de la vie privée.


        <h1 class="colorTitle">Article 5 - Propriété intellectuelle</h1>

        <p>Tous les documents techniques, produits, photographies, textes, logos, dessins, vidéos, etc., sont
          soumis
          à des droits d'auteur et sont protégés par le Code de la propriété intellectuelle. Même remis à des
          tiers,
          ils demeurent la propriété exclusive de l'Editeur, seul titulaire des droits de propriété
          intellectuelle sur ces documents, qui doivent lui être rendus à sa demande.

        <p>Nos clients s'engagent à ne faire aucun usage de ces documents, susceptible de porter atteinte aux
          droits
          de propriété industrielle ou intellectuelle du fournisseur et s'engagent à ne les divulguer à aucun
          tiers, en
          dehors d'une autorisation expresse et préalable donnée par l'Editeur.


        <h1 class="colorTitle">Article 6 - Liens hypertextes</h1>

        <p>La mise en place par l'Enseignant de tous liens hypertextes vers tout ou partie du site est
          strictement interdite,
          sauf autorisation préalable et écrite de l'Éditeur.
          Cette autorisation doit être sollicitée par courriel à l'adresse suivante : bosscool@boscool.be.
          Dans le cas où un lien hypertexte aurait été mis en place sans autorisation, l'Editeur est libre
          d'exiger le retrait de ce lien
          sous peine de banissement de l'application.

        <p>L'Éditeur est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce
          soit
          sa décision. Dans le cas où l'Éditeur accorderait son autorisation, celle-ci n'est dans tous les cas
          que
          temporaire et pourra être retirée à tout moment, sans obligation de justification à la charge de
          l'Éditeur.

        <p>Dans tous les cas, tout lien devra être retiré sur simple demande de l'Éditeur.

        <p>Toute information accessible via un lien vers d'autres sites n'est pas sous le contrôle de l'Éditeur
          qui
          décline toute responsabilité quant à leur contenu.


        <h1 class="colorTitle">Article 7- Protection des données personnelles</h1>

        <h4 class="colorTitle2">Données collectées</h4>

        <p>Les données à caractère personnel qui sont collectées sur ce site sont les suivantes:

        <ul class="paddingLeft35">
          <li> ouverture de compte : lors de la création du compte de l'Enseignant :</li>
          <ul>
            <li>nom;</li>
            <li>prénom;</li>
          </ul>
          <li>connexion : lors de la connexion de l'Enseignant à l'application, celui-ci enregistre,
            notamment, ses
            nom, prénom, données de connexion, d'utilisation, de localisation et ses eventuelles données
            relatives au
            paiement;</li>
          <li>profil : l'utilisation des prestations prévues avec l'application pourrait permettre de
            renseigner un
            profil, pouvant comprendre une adresse et un numéro de téléphone;</li>
          <li>paiement : dans le cadre du paiement des produits et prestations proposés par l'application,
            celle-ci
            enregistre des données financières relatives au compte bancaire ou à la carte de crédit de
            l'Enseignant;</li>
          <li>cookies : les cookies sont utilisés, dans le cadre de l'utilisation de l'application.
            L'Enseignant a la
            possibilité de désactiver les cookies à partir des paramètres de son navigateur.</li>
        </ul>



        <h4 class="colorTitle2">Utilisation des données personnelles</h4>

        <p>Les données personnelles collectées auprès des Enseignants ont pour objectif la mise à disposition
          des
          services de l'application, leur amélioration et le maintien d'un environnement sécurisé. Plus
          précisément,
          les utilisations sont les suivantes :

        <ul class="paddingLeft35">
          <li>accès et utilisation à l'application par l'Enseignant;</li>
          <li>gestion du fonctionnement et optimisation de l'application;</li>
          <li>organisation des conditions d'utilisation des Services de paiement;</li>
          <li>vérification, identification et authentification des données transmises par l'Enseignant;</li>
          <li>proposition à l'Enseignant de la possibilité de communiquer avec d'autres Enseignants de
            l'application;</li>
          <li>mise en oeuvre d'une assistance Enseignants;</li>
          <li>personnalisation des services en affichant des publicités en fonction de l'historique de
            navigation de
            l'Enseignant, selon ses préférences;</li>
          <li>prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et
            gestion des incidents de sécurité;</li>
          <li>gestion des éventuels litiges avec les Enseignants;</li>
        </ul>


        <p>Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :

        <ul class="paddingLeft35">
          <li>lorsque l'Enseignant utilise les services de paiement, pour la mise en oeuvre de ces services,
            l'application est en relation avec des sociétés bancaires et financières tierces avec lesquelles
            elle a
            passé des contrats;</li>
          <li>lorsque l'Enseignant publie, dans les zones de commentaires libres de l'application, des
            informations
            accessibles aux autres Utilisateurs;</li>
          <li>lorsque l'Enseignant autorise le site web d'un tiers à accéder à ses données;</li>
          <li>lorsque l'application recourt aux services de prestataires pour fournir l'assistance
            Enseignants, la
            publicité et les services de paiement. Ces prestataires disposent d'un accès limité aux données
            de
            l'Enseignant, dans le cadre de l'exécution de ces prestations, et ont une obligation
            contractuelle de les
            utiliser en conformité avec les dispositions de la réglementation applicable en matière
            protection des
            données à caractère personnel;</li>
          <li>si la loi l'exige, l'Editeur peut effectuer la transmission de données pour donner suite aux
            réclamations présentées contre l'application et se conformer aux procédures administratives et
            judiciaires;</li>
          <li>si l'application est impliquée dans une opération de fusion, acquisition, cession d'actifs ou
            procédure
            de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses
            actifs, y
            compris les données à caractère personnel. Dans ce cas, les Enseignants seraient informés, avant
            que les données à
            caractère personnel ne soient transférées à une tierce partie.</li>
        </ul>

        <h4 class="colorTitle2">Sécurité et confidentialité</h4>

        <p> L'Editeur met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en
          matière
          de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions
          et accès
          non autorisés. Toutefois, il est à signaler qu'internet n'est pas un environnement complètement
          sécurisé et
          l'application ne peut pas garantir la sécurité de la transmission ou du stockage des informations
          sur
          internet.

        <h4 class="colorTitle2"> Mise en oeuvre des droits des Enseignants</h4>


        <p>En application de la réglementation applicable aux données à caractère personnel, les Enseignants
          disposent des droits suivants, qu'ils peuvent exercer en faisant leur demande à l'adresse suivante
          : bosscool@bosscool.be.

        <ul class="paddingLeft35">

          <li>le droit d’accès : ils peuvent exercer leur droit d'accès, pour connaître les données
            personnelles
            les concernant. Dans ce cas, avant la mise en œuvre de ce droit, l'Editeur peut demander une
            preuve de
            l'identité de l'Enseignant afin d'en vérifier l'exactitude. L'Editeur peut à tout moment
            modifier les droits
            d'accès octroyés aux tuilisateurs de "BossCool" et ce, sans préavis ni justification.</li>

          <li>le droit de rectification : si les données à caractère personnel détenues par l'aplication sont
            inexactes, ils peuvent demander la mise à jour des informations.</li>

          <li>le droit de suppression des données : les Enseignants peuvent demander la suppression de leurs
            données à caractère personnel, conformément aux lois applicables en matière de protection des
            données. </li>

          <li>le droit à la limitation du traitement : les Enseignants peuvent de demander à l'Editeur de
            limiter
            le traitement des données personnelles conformément aux hypothèses prévues par le RGPD. </li>

          <li>le droit de s’opposer au traitement des données : les Enseignants peuvent s’opposer à ce que ses
            données soient traitées conformément aux hypothèses prévues par le RGPD. </li>

          <li>le droit à la portabilité : ils peuvent réclamer que l'Editeur leur remette les données
            personnelles
            qui lui sont fournies pour les transmettre à un quelqu'un d'autre.</li>

        </ul>

        <h4 class="colorTitle2">Evolution de la présente clause</h4>

        <p>L'Editeur se réserve le droit d'apporter toute modification à la présente clause relative à la
          protection
          des données à caractère personnel à tout moment.


        <h1 class="colorTitle">Article 8 - Cookies</h1>

        <p>L'application peut collecter automatiquement des informations standards. Toutes les informations
          collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration
          du trafic
          utilisant ce site, pour en développer la conception et l'agencement et à d'autres fins
          administratives et de
          planification et plus généralement pour améliorer le service que nous vous offrons.


        <h1 class="colorTitle">Article 9- Loi applicable</h1>


        <p>Les présentes conditions générales d'utilisation sont soumises à l'application du droit belge.

        <p>Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la compétence
          des
          tribunaux belges.

      </div>

      <div class="modal-footer" style="text-align: center; justify-content: center;">
        <button type="submit" class="btn btn-primary" routerLink="/profile" (click)="modal.close('Ok') "
          style="margin-right: 25px;">Accepter
        
          <span class="icon-iconAccepterCGU" style="font-size: 1.3em ;"><span class="path1"></span><span
              class="path2"></span></span>

        </button>
        <button type="button" class="btn btn-danger" (click)="modal.close(); authservice.SignOut();">Refuser
          <i class="icon-iconDeconnexion" style="font-size: 1.3em;"></i>
        </button>

      </div>

    </div>
  </ng-template>