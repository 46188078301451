import { Component, } from '@angular/core';
import { AuthService } from "../auth.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";
import { WebcamImage, WebcamUtil } from 'ngx-webcam';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColleaguesService } from '../../colleagues/colleagues.service';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { ParametersService } from '../../others/parameters.service';
import { LocalUser } from '../local-user';



//

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent {
  public quotaForm: FormGroup
  realRole = { 1: "Invité", 1.5: "test", 2: "Utilisateur", 3: "Administrateur", 4: "Super-Zéro" }
  downloadURL: Observable<string>;
  url: string | ArrayBuffer;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public multipleWebcamsAvailable = false;
  // latest snapshot
  public webcamImage: WebcamImage = null;
  mode: String = "Defaut";
  closeModal: string;
  //moderatorEmailAddress: string;
  localUserData: LocalUser;
  userRole: string;
  constructor(
    public authservice: AuthService,
    private colleaguesApi: ColleaguesService,       // Inject CRUD API in constructor
    private storage: AngularFireStorage,
    private modalService: NgbModal,
    private fb: FormBuilder,            // Inject Form Builder service for Reactive forms
    private toastr: ToastrService,       // Toastr service for alert message
    //public parametersApi: ParametersService,

  ) {
    //this.aglobalUserData=globalUserData
    //console.log(Date() ,'constructor')
  }


  ngOnInit(): void {
    //console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));

      this.quotaForm = this.fb.group({
        quota: ['', [Validators.required, Validators.pattern('^[0-9]+$')]]
      })

      this.quotaForm.controls['quota'].reset

      WebcamUtil.getAvailableVideoInputs()
        .then(
          (mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
          }
        )
        .catch((error) => {
          console.log(Date(), error);
        });

      /*this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
        this.moderatorEmailAddress = data.payload.toJSON()['moderatorEmailAddress']

      })*/
    }
    //console.log(this.localUserData.role )
    if (this.localUserData.role.toString() == "1,5") {
      
      this.userRole = "Utilisateur sans photo"
    }
    else {
      this.userRole = this.realRole[this.localUserData.role]
    }


  }

  onFileSelected(file) {
    //var n = Date.now();
    //const file = event.target.files[0];
    const filePath = `ProfileImages/${this.localUserData.uid}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.authservice.updateUserData('photoURL', url.toString())
              this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
              this.authservice.UpdateCurrentUserPhotoUrl(url.toString())
              this.colleaguesApi.UpdateCurrentColleaguePhotoURL(this.localUserData.emailID, url.toString());
            }
            //console.log(Date() ,this.fb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          //console.log(Date() ,url);
        }
      });
  }

  imgChangeEvt: any = "";
  cropImgPreview: any = "";

  onFileChange(event: any): void {
    this.mode = "Fichier";
    //console.log(Date() ,'onfilechange');

    //this.authservice.UpdateCurrentUserPhotoUrl('Einstein');
    //this.authservice.updateUserData('photoURL', 'Einstein')
    this.imgChangeEvt = event;

  }


  cropImg(e: ImageCroppedEvent) {

    this.cropImgPreview = e.base64

  }

  submit() {

    //console.log(Date() ,this.mode);
    //console.log(Date() ,'Bouton VAlider')
    let File = base64ToFile(this.cropImgPreview);
    //let generatedImage = window.URL.createObjectURL(File);
    //console.log(Date() ,File)
    this.onFileSelected(File)
    this.mode = "Defaut"
    //window.open(generatedImage);

  }
  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }

  public deletePicture() {

    this.url = null;
    this.authservice.UpdateCurrentUserPhotoUrl('Einstein');
    this.authservice.updateUserData('photoURL', 'Einstein')
    this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
    this.colleaguesApi.UpdateCurrentColleaguePhotoURL(this.localUserData.emailID, 'Einstein');
  }
  launchWebcam() {
    this.mode = "Webcam"
    //console.log(Date() ,this.mode);
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }


  cancelSnapshot() {
    this.mode = 'Defaut'

  }


  triggerSnapshot() {
    this.mode = "CropWebcam"
    //console.log(Date() ,this.mode);
    this.trigger.next();
    //console.log(Date() ,this.webcamImage.imageAsDataUrl);

  }

  handleImage(webcamImage: WebcamImage) {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }


  triggerModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch((error) => {
        console.log(Date(), error);
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  updateQuota() {

    //console.log(Date() ,globalUserData)
    //console.log(Date() ,this.quotaForm.get('quota').value);
    if (this.quotaForm.get('quota').value < 1) {
      this.quotaForm.get('quota').setValue(1);
    }
    this.authservice.updateUserData('quota', parseInt(this.quotaForm.get('quota').value))


    this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));


    this.colleaguesApi.UpdateCurrentColleagueQuota(this.localUserData.emailID, parseInt(this.quotaForm.get('quota').value));
    this.toastr.success('Votre quota personnel a bien été modifié à ' + this.quotaForm.get('quota').value + ' périodes de 50 minutes')


  }






}












