<header class="page-header" id="header">
  <app-header></app-header>
</header>
<div class="d-flex justify-content-center  align-items-center border-bottom"
  style="margin-top: 100px; margin-bottom: 10px;">
  <h1 style="color: white; margin-right: 25px;">Paramètres globaux </h1>
  <!-- It won't show if there is no travcol data -->


</div>



<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div style="text-align: right;margin-bottom: -25px">
          <button ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataParameters)">
            <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
          </button>
        </div>
        <div class="card_top center">

        </div>
        <div class="card_bottom">

          <form [formGroup]="ParametersForm" (ngSubmit)="submitParametersData()" novalidate>
            <div class="card_info">

              <label>Ecole</label>
              <input placeholder="Nom de l'établissement" type="text" formControlName="schoolName" class="formControl left" required>
              <p *ngIf="schoolName.touched && schoolName.invalid" class="error"><sup>*</sup>Veuillez entrer un nom
                d'école.</p>
              <p *ngIf="schoolName.errors?.pattern" class="error"><sup>*</sup>Le nom de l'école peut uniquement contenir
                des lettres,
                des chiffres et des traits d'union.</p>

              <div *ngIf="this.localUserData.role &gt; 3">
                <label>Date pivot</label>
                <input type="date" formControlName="pivotDate" class="formControl left" required>
                <p *ngIf="pivotDate.touched && pivotDate.invalid" class="error"><sup>*</sup>Veuillez entrer une date
                  pivot.</p>
                <p *ngIf="pivotDate.errors?.pattern" class="error"><sup>*</sup>La date doit être du type
                  Jour Mois Année</p>


                <label>Email modérateur</label>
                <input type="text" placeholder="ex: moderateur@ecole.ext" formControlName="moderatorEmailAddress"
                  class="formControl left" required>
                <!--p *ngIf="moderatorEmailAddress.touched && moderatorEmailAddress.invalid" class="error"><sup>*</sup>Veuillez entrer un email.</p>
              <p *ngIf="moderatorEmailAddress.errors?.pattern" class="error"><sup>*</sup>Veuillez formater l'email convenablement</p-->
              </div>


              <label>Quota général</label>
              <input type="text" formControlName="globalQuota" class="formControl left" required>
              <p *ngIf="globalQuota.touched && globalQuota.invalid" class="error"><sup>*</sup>Veuillez entrer un quota.
              </p>
              <p *ngIf="globalQuota.errors?.pattern" class="error"><sup>*</sup>Utilisez uniquement des chiffres.</p>

              <!--<label>Afficher le total dans le carnet imprimé</label>
              <input type="checkbox"  formControlName="calculateTotalInPrint" class="formControl left" >-->

              <label>Total dans le carnet</label><br>
              <label class="toggle-switch">
                <input type="checkbox" formControlName="calculateTotalInPrint">
                <div class="slider"></div>

              </label>
              <label *ngIf="calculateTotalInPrint.value"
                style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Expresso et la progress bar seront
                affichés dans le carnet</label>
              <label *ngIf="!calculateTotalInPrint.value"
                style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Expresso et la progress bar ne
                seront pas affichés dans le carnet</label><br>

              <label>Type de TC</label><br>
              <label class="toggle-switch">
                <input type="checkbox" formControlName="TCType">
                <div class="slider"></div>

              </label>
              <label *ngIf="TCType.value" style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Le champ
                "Type de TC" sera affiché dans l'ajout et les listes des TC</label>
              <label *ngIf="!TCType.value" style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Le
                champ "Type de TC" ne sera pas affiché dans l'ajout et les listes des TC</label><br>



              <label>Décisions</label><br>
              <label class="toggle-switch">
                <input type="checkbox" formControlName="TCDecisions">
                <div class="slider"></div>

              </label>
              <label *ngIf="TCDecisions.value" style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Le
                champ "Décisions" sera affiché dans l'ajout et les listes des TC</label>
              <label *ngIf="!TCDecisions.value" style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Le
                champ "Décisions" ne sera pas affiché dans l'ajout et les listes des TC</label><br>


              <!--  <label>Joindre un PDF</label><br>
              <label class="toggle-switch">
                <input type="checkbox" formControlName="PDFJoin" >
                <div class="slider" ></div>
                
              </label>
              <label *ngIf="PDFJoin.value" style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Il sera possible de joindre un PDF dans l'ajout des TC</label>
              <label *ngIf="!PDFJoin.value" style="color: white;font-size: 13pt;max-width:80%;margin-left: 15px;">Il ne sera pas possible de joindre un PDF dans l'ajout des TC</label><br>
-->

              <!--<label>Année scolaire</label>
          <ng-multiselect-dropdown class="formControl left custom-font-size" required
          

          [placeholder]="'Choisissez une année scolaire'"
          [settings]="dropdownSettings"
          [data] ="schoolYearList"
          formControlName="schoolYear"
          searchPlaceholderText='Entrez une année scolaire'
          
          
          (onSelect)="onItemSelect($event)">
          </ng-multiselect-dropdown>
          
          <p *ngIf="schoolYear.touched && schoolYear.invalid" class="error"><sup>*</sup>Veuillez choisir une année scolaire</p>
        -->
              <label style="margin-bottom: 5px;">Logo de votre école</label>
              <label *ngIf="this.mode=='Defaut'" class="hoverable" style="margin:auto;height: 138px;
              width: 266px;" for="logoInput"> <!-- car bordure de 5px de chaque cote donc augmentation de 10px-->
                <img src="{{ParametersForm.controls['schoolLogo'].value}}" style="height: 128px;
                width: 256px;">

                <div class="hover-text">Choisir un Logo</div>
                <div class="background">
                </div>
                <input id="logoInput" type='file' accept="image/*" (change)="onLogoChange($event)">
              </label>

              <image-cropper *ngIf="this.mode=='Logo'" [imageChangedEvent]="imgChangeLogo" [maintainAspectRatio]="true"
                [aspectRatio]="4 / 2" [resizeToWidth]="256" format="png" (imageCropped)="cropImgLogo($event)"
                (imageLoaded)="imgLoad()" (cropperReady)="initCropper()" (loadImageFailed)="imgFailed()">
              </image-cropper>
              <div class="d-flex justify-content-center  align-items-center">

                <button *ngIf="this.mode=='Logo'" class="btn btn-danger" style="margin-right: 15px;"
                  (click)="cancel()">Annuler
                  <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
                      class="path2"></span></span>
                </button>

                <button *ngIf="this.mode=='Logo'" class="btn btn-primary" (click)="submitLogo()">Valider
                  <span class="icon-iconValider" style="font-size: 1.3em;"><span class="path1"></span><span
                      class="path2"></span></span>
                </button>
              </div>
              <!--input type="text" formControlName="schoolLogo" class="formControl left" required-->
              <!--<p *ngIf="emailAddress.touched && emailAddress.invalid" class="error"><sup>*</sup>Veuillez entrer une adresse email</p>
          <p *ngIf="emailAddress.errors?.pattern" class="error"><sup>*</sup>Voyons, ceci n'est pas une adresse email !</p>
          -->

              <label style="margin-bottom: 5px;">Image de la page de garde de votre carnet</label>
              <label *ngIf="this.mode=='Defaut'" class="hoverable" style="margin:auto;height: 323.6px;
              width: 266px;" for="coverInput"> <!-- car bordure de 5px de chaque cote donc augmentation de 10px-->
                <img src="{{ParametersForm.controls['coverSheetPicture'].value}}" style="height: 312.6px;
                width: 256px;">

                <div class="hover-text">Choisir une couverture</div>
                <div class="background">
                </div>
                <input id="coverInput" type='file' accept="image/*" (change)="onCoverChange($event)">
              </label>


              <image-cropper *ngIf="this.mode=='Cover'" [imageChangedEvent]="imgChangeCover"
                [maintainAspectRatio]="true" [aspectRatio]="0.819" [resizeToWidth]="256" format="png"
                (imageCropped)="cropImgCover($event)" (imageLoaded)="imgLoad()" (cropperReady)="initCropper()"
                (loadImageFailed)="imgFailed()">
              </image-cropper>

              <div class="d-flex justify-content-center  align-items-center">
                <button *ngIf="this.mode=='Cover'" class="btn btn-danger" style="margin-right: 15px;"
                  (click)="cancel()">Annuler
                  <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
                      class="path2"></span></span>
                </button>
                <button *ngIf="this.mode=='Cover'" class="btn btn-primary" (click)="submitCover()">Valider
                  <span class="icon-iconValider" style="font-size: 1.3em;"><span class="path1"></span><span
                      class="path2"></span></span>
                </button>


              </div>
            </div>

            <div *ngIf="this.mode=='Defaut'" class="card_creator">
              <button type="button" class="btn btn-danger "
                style="border-radius:10px;padding: 10px;margin-top: 10px;margin-right: 30px;margin-bottom: 10px;"
                (click)="ResetForm()">Défaut
                <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>
              <button type="submit" class="btn btn-primary"
                style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px;"
                [disabled]="!ParametersForm.valid">
                Sauvegarder
                <span class="icon-iconValider" style="font-size: 1.3em;"><span class="path1"></span><span
                    class="path2"></span></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalDataParameters let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 13pt;margin-bottom: -20px;">

    Cette fenêtre permet de configurer les paramètres généraux qui sont répercutés un peu partout dans BossCool :
    <ul style="padding-left: 35px;">

      <li>Le <strong>nom de l'établissement</strong>, le <strong>logo de votre école</strong> et
        l'<strong>image de page de garde</strong> apparaîssent sur le carnet des travaux collaboratifs.
        <br>
        Tous les utilisateurs verront donc ce que vous avez configuré ici.
      </li>
      <li>En cliquant sur <strong>afficher le total</strong>, vous pouvez choisir si Expresso et sa progress bar
        apparaîssent ou pas dans le carnet des travaux collaboratifs.</li>
      <li>L'<strong>email modérateur</strong> est l'adresse email complète de la personne (administrateur de BossCool)
        qui recevra les demandes d'ajout d'invité et le signalement des photos.</li>
      <li>Le <strong>suffixe email</strong> est important car il empêchera vos utilisateurs d'essayer d'inviter
        un collègue qui possède une adresse email avec ce suffixe. Cela permet d'éviter des demandes intempestives
        au modérateur. Aussi, il apparaîtra dans les fenêtres d'inscription et de login.
        N'oubliez donc pas de le configurer !</li>
      <li>Le <strong>quota général</strong> est le quota par défaut qui est attribué à chaque utilisateur.
        Cependant, chacun d'entre eux peut le modifier à travers la page profil. Si ce quota a été changé par
        l'utilisateur c'est celui-là qui apparaîtra dans le carnet des travaux collaboratifs </li>
    </ul>

    <span style="text-decoration: underline;"> Remarques</span> :
    <ul style="padding-left: 35px;">
      <li>En cliquant sur le bouton <strong>défaut</strong> <span class="icon-iconEffacer"
          style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span> vous effacerez tous
        les champs et remettrez les images par défaut de BossCool
        (elles servent d'exemple pour la première visualisation du carnet).</li>
      <li>Attention : les modifications se font en temps réel. L'utilisateur verra les changements dès que vous avez
        cliqué
        sur <strong>valider</strong> <span class="icon-iconValider" style="font-size: 1.3em;"><span
            class="path1"></span><span class="path2"></span></span>.
      </li>
    </ul>




  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
      <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
          class="path2"></span></span>
    </button>
  </div>
</ng-template>