
import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from "../../boilerplate/auth.service";
import { Router } from "@angular/router";

import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  localUserData: LocalUser;;
  customer= environment.customer
  
  //isAuth: boolean;
 
  constructor(
  
  public authservice: AuthService,
  public router: Router,
  public ngZone: NgZone,
  
  ) { // console.log(Date() ,'constructor')
  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit header')
    if (localStorage.getItem('userData') !== null ) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)
  
    }
    


  

}

  onSignOut() {
 
    

    //console.log(Date() ,'on sign out header');
    this.authservice.SignOut();
    this.router.navigate(['welcome'])
   
  }


  onSignIn() {
   
    this.router.navigate(['sign-in']);
  }

  onBack() {
    document.getElementById("footer").style.display = "block";
    this.router.navigate(['travcols-list']);
   
  }
 
  
  PrintPage(){
   
    var css = '@page { size: landscape; }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');


  style.media = 'print';

  style.appendChild(document.createTextNode(css));


  head.appendChild(style);

  window.print();

  }

}
