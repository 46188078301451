import { Component, OnInit } from '@angular/core';
import { TravcolsService } from '../travcols.service';  // CRUD API service class
import { ToastrService } from 'ngx-toastr';      // Alert message using NGX toastr
import { TravCol } from 'src/app/components/travcols/travcol';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/components/boilerplate/auth.service';
import { ColleagueOfTravcol } from '../colleague-of-travcol';
import * as CryptoJS from 'crypto-js';
import { LocalUser } from '../../boilerplate/local-user';
import { ParametersService } from '../../others/parameters.service';
import { ColleaguesService } from '../../colleagues/colleagues.service';


@Component({
  selector: 'app-s-list',
  templateUrl: './travcols-list.component.html',
  styleUrls: ['./travcols-list.component.css']
})
export class TravColsListComponent implements OnInit {

  p: number = 1;                      // Settup up pagination variable
  public travcol: TravCol[];                 // Save travcols data in travcol's array.
  public colleaguesOfTravcol: ColleagueOfTravcol[];
  hideWhenNoTravCol: boolean = false; // Hide travcols data table when no travcol.
  noData: boolean = false;            // Showing No travcol Message, when no travcol in database.
  preLoader: boolean = true;          // Showing Preloader to show user data is coming for you from thre server(A tiny UX Shit)
  closeModal: string;
  isOwnerOfTC: boolean = false;
  public sort: string = 'date';
  public order: string = 'normal';
  emailID: string;
  public searchTextColleagues: string;
  public searchTextGoals: string;
  public searchTextDecisions: string;
  public searchTextInitials: string;
  public searchTextTypeOfTC: string;
  public search: string;
  public viewSearch = { "colleaguesListString": false, "goals": false, "decisions": false, "initials": false, "typeOfTC": false }
  public Names = ["colleaguesListString", "goals", "decisions", "initials", "typeOfTC"]
  //public colleaguesList: string
  public userQuotaInMinutes;
  hasValidatedFound: boolean = false;
  toBeDisplayed: boolean;
  localUserData: LocalUser;
  totalPeriods = 0;
  ;
  atLeastOneAcceptation: boolean = false;
  parameters: Object;


  constructor(

    private modalService: NgbModal,
    public travcolsApi: TravcolsService, // Inject travcol CRUD services in constructor.
    public toastr: ToastrService, // Toastr service for alert message
    public router: Router,
    public authservice: AuthService,
    public parametersApi: ParametersService,
    public colleaguesApi: ColleaguesService

  ) { //console.log(Date() ,'constructor')

  }


  ngOnInit() {
    //console.log(Date() ,'ngoninit')
    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      // //console.log(Date() ,this.localUserData)


      this.userQuotaInMinutes = this.localUserData.quota * 50
      //console.log(Date() ,this.userQuotaInMinutes)
      this.emailID = this.localUserData.emailID
    }


    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.parameters = data.payload.toJSON()
    })
    let s = this.travcolsApi.GetTravColsList(this.localUserData.emailID);
    s.valueChanges().subscribe(sData => {
      this.dataState(sData); // Initialize travcol's list, when component is ready
      let travcols = {}
      sData.forEach(sElement => {
        travcols = { ...travcols, ...sElement }
      });
      this.travcol = [];

      Object.values(travcols).forEach(travcol => {
        this.colleaguesOfTravcol = [];

        Object.values(travcol['colleaguesOfTravcol']).forEach(colleaguesOfTravcol => {
          colleaguesOfTravcol['key'] = colleaguesOfTravcol['colleagueEmailID']




          this.colleaguesOfTravcol.push(colleaguesOfTravcol as ColleagueOfTravcol)
          if (colleaguesOfTravcol['colleagueEmailID'] == travcol['userEmailID'] && colleaguesOfTravcol['hasValidated'] == true) {
            this.toBeDisplayed = true
          }
        });
        travcol["colleaguesOfTravcol2"] = this.colleaguesOfTravcol
        if (this.toBeDisplayed) {

          this.travcol.push(travcol as TravCol);
          this.toBeDisplayed = false
        }
      });
      this.dataState(this.travcol)
    });
  }

  // Using valueChanges() method to fetch simple list of travcols data. It updates the state of hideWhenNoTravCol, noData & preLoader variables when any changes occurs in travcol data list in real-time.
  dataState(data) {

    this.preLoader = false;
    if (data.length <= 0) {
      this.hideWhenNoTravCol = false;
      this.noData = true;
    } else {
      this.hideWhenNoTravCol = true;
      this.noData = false;
    }

  }


  getTotal(travcol: TravCol[]) {

    let myObject = [];

    myObject['totalMinute'] = 0;
    myObject['totalPourcent'] = 0;
    myObject['totalPeriode'] = 0;

    if (travcol != null) {
      travcol.forEach(element => {

        //console.log(Date() ,element['colleaguesOfTravcol'][element.userEmailID]['hasActivated'])
        if (element['colleaguesOfTravcol'][element.userEmailID]['hasActivated'] == true && element.isActivable == true) {


          myObject['totalMinute'] += Number(element['duration'])
        }

      });

    }

    myObject['totalPeriode'] = myObject['totalMinute'] / 50;
    myObject['totalPeriode'] = Number(myObject['totalPeriode'].toFixed(1));
    if (this.totalPeriods != myObject['totalPeriode']) {
      this.colleaguesApi.UpdateTotalPeriods(this.emailID, myObject['totalPeriode'].toFixed(0))
      this.totalPeriods = myObject['totalPeriode']
    }
    myObject['totalPourcent'] = Math.round(myObject['totalMinute'] / (this.localUserData.quota / 2));




    return myObject;

  }


  setSort(sort: string) {
    this.sort = sort;
    this.order = 'normal';
    // console.log(Date() ,'sort',sort)
  }

  setInvertSort(sort: string) {
    this.sort = sort;
    this.order = 'invert'
  }

  sortBy(prop: string) {
    if (prop == 'date') {
      this.order = "invert";
    }
    if (this.travcol != null) {
      if (this.order == 'normal' && this.travcol) {
        if (this.sort == 'duration') {
          //console.log(Date() ,'bouh')
          // console.log(Date() ,JSON.stringify(this.travcol[1][this.sort]))
          return this.travcol.sort((a, b) => Number(a[prop]) > Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }

        return this.travcol.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
      }
      else if (this.order == 'invert' && this.travcol) {
        if (this.sort == 'duration') {
          //console.log(Date() ,'bouh')
          // console.log(Date() ,JSON.stringify(this.travcol[1][this.sort]))
          return this.travcol.sort((a, b) => Number(a[prop]) < Number(b[prop]) ? 1 : Number(a[prop]) === Number(b[prop]) ? 0 : -1)
        }
        return this.travcol.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1)
      }
    }
  }


  Search(search) {

    //console.log(Date() ,this.travcol)
    // console.log(Date() ,search)
    //console.log(Date() ,this.viewSearch)

    this.searchTextColleagues = '';
    this.searchTextGoals = '';
    this.searchTextDecisions = '';
    this.searchTextInitials = '';
    this.searchTextTypeOfTC = '';

    this.Names.forEach(element => {

      if (element == search) {
        this.viewSearch[element] = true
      }
      else {
        this.viewSearch[element] = false
      }

    });


    //console.log(Date() ,this.viewSearch)
  }

  EndSearch(search) {
    this.Names.forEach(element => {
      //console.log(Date() ,'element',element)
      //console.log(Date() ,'search',search)
      if (element == search) {
        this.viewSearch[element] = false
      }

    });
    this.searchTextColleagues = '';
    this.searchTextGoals = '';
    this.searchTextDecisions = '';
    this.searchTextInitials = '';
    this.searchTextTypeOfTC = '';
  }



  //vérifie si le user current est ben le propriétaire du travcol
  getmatching(travcol) {
    //console.log(Date() ,travcol)
    if (travcol.ownerID != null) {
      if (travcol.ownerID == this.emailID) {
        return this.isOwnerOfTC = true;
      }
      else {
        return this.isOwnerOfTC = false;
      }
    }
  }



  deleteChoice(modal, travcol) {
    //console.log(Date() ,travcol);
    this.getmatching(travcol);
    this.triggerModal(modal, travcol);
  }

  triggerModal(content, travcol) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
        //console.log(Date() ,this.closeModal);
        this.modalWChoice(this.closeModal, travcol);
      }, (res) => {

        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        //console.log(Date() ,this.closeModal );
        this.modalWChoice(this.closeModal, travcol);
      })
      .catch(function (error) {
        console.log(Date(), "triggerModal failed: " + error.message)
      });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      //console.log(Date() ,'by pressing ESC')
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      //console.log(Date() ,'by clicking on a backdrop')
      return 'by clicking on a backdrop';
    } else {
      //console.log(Date() ,`with: ${reason}`)
      return `with: ${reason}`;
    }
  }

  modalWChoice(choice: string, travcol) {
    switch (choice) {
      case "Closed with: Modifier": {
        //console.log(Date() ,"Modifier")
        let path = "/edit-travcol/" + travcol.userEmailID + "/" + travcol.ownerID + "/" + travcol.key
        //console.log(Date() ,path)
        this.router.navigate([path]);

        break;
      }
      case "Closed with: Effacer": {

        if (this.isOwnerOfTC) {
          //extermination du travcol

          Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
            //console.log(Date() ,colleaguesOfTravcolKey)
            this.travcolsApi.DeleteTravColForOneColleague(colleaguesOfTravcolKey, travcol);
          })

          this.toastr.success("Voilà, c'est malin, maintenant vous avez tout effacé! Tant pis si les autres vous rouspètent !"); // Alert message will show up when travcol successfully deleted.
          break;

        }
        else {
          this.removeFromTravcol(travcol)

          break;
        }

      }
      default: {
        //statements; 
        break;
      }
    }
  }

  removeFromTravcol(travcol) {
    //se retirer du travcol
    //console.log(Date() ,travcol.key)


    //Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
    //console.log(Date() ,colleaguesOfTravcolKey)
    //console.log(Date() ,data)
    //if (data != null) {
    if (Object.keys(travcol.colleaguesOfTravcol).length == 2) {

      Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
        //console.log(Date() ,colleaguesOfTravcolKey)
        this.travcolsApi.DeleteTravColForOneColleague(colleaguesOfTravcolKey, travcol);
      })
      this.toastr.success("Le travcol a été complètement effacé car vous n'étiez que deux collègues"); // Alert message will show up when travcol successfully deleted.
    }
    else {
      this.travcolsApi.DeleteTravCol(travcol)
      delete travcol.colleaguesOfTravcol[travcol.userEmailID]
      //console.log(Date() ,travcol)

      Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
        //console.log(Date() ,colleaguesOfTravcolKey)
        travcol.userEmailID = colleaguesOfTravcolKey
        //delete travcol.colleaguesList
        this.travcolsApi.UpdateTravcol(travcol) //écrire colleague dans travcol 


      })
      
      this.toastr.success("Vous avez bien été retiré du TC, il n'est maintenant plus dans votre liste et votre quota a été diminué. ");

    }
  }

  getMatchingTable(travcol) {

    if (travcol != null) {
      if (this.emailID == travcol.ownerID) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  toggleHasActivated(travcol) {
    if (travcol.isActivable) {
      travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated'] = !travcol.colleaguesOfTravcol[travcol.userEmailID]['hasActivated']
      Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {
        //delete travcol.colleaguesList
        travcol.userEmailID = colleaguesOfTravcolKey
        this.travcolsApi.UpdateTravcol(travcol)
      });
    }

    Object.keys(travcol.colleaguesOfTravcol).forEach(colleaguesOfTravcolKey => {

      this.travcolsApi.UpdateTravcolForOneUser(colleaguesOfTravcolKey, travcol)
    })

  }







}