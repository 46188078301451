<header class="page-header" id="header" >
  <app-header></app-header>
</header>

<div class="center" style="color: white;margin-top: 100px;">
  <a href="http://www.bosscool.be" style="cursor:pointer">
    <img src="./assets/images/LogoBosscoolTitre.png" width="180px" height="60px" />
  </a>

  <br>
  <h2><strong>L'application qui vous aide à mentionner votre travail collaboratif</strong></h2>
</div>





<div class="wrapper">
  <div class="cards_wrap">
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">
          <img src="./assets/images/inscriptions.png" width="44%" height="44%">
        </div>
        <div class="card_bottom">
          <!--<div class="card_category">
            Inscription
          </div>-->
          <div class="card_info">

            <!--<p class="title">10 Best Things about Travel</p>-->
            <p>
              Vous n'avez pas encore utilisé
              <span>
                <img src="./assets/images/LogoBosscool.png" width="90px" height="30px" />
              </span> et vous aimeriez commencer sans plus attendre une seconde.
            </p>
          </div>
          <div class="card_creator">
            <button type="button" class="btn btn-primary" routerLink="/sign-up">
              Inscription {{this.env}}
              <span class="icon-iconInscription" style="font-size: 1.3em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card_item" style="margin-bottom: 20px;">
      <div class="card_inner">
        <div class="card_top center">
          <img src="./assets/images/collaboratif.png" width="68%" height="68%">
          <!--77-->
        </div>
        <div class="card_bottom">
          <!--<div class="card_category">
            Inscription
          </div>-->
          <div class="card_info">

            <!--<p class="title">10 Best Things about Travel</p>-->
            <p>
              Vous êtes déjà enregistré et vous désirez gérer l'encodage de vos travaux collaboratifs avec <span>
                <img src="./assets/images/LogoBosscool.png" width="90px" height="30px" />
              </span>.
            </p>
          </div>
          <div class="card_creator">
            <button type="button" class="btn btn-primary" routerLink="/sign-in">
              Connexion {{this.env}}
              <span class="icon-iconConnexionNew" style="font-size: 1.3em;"></span>
            </button>
          </div>
        </div>
      </div>
    </div>