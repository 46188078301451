// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  environmentName: 'Development',
  customer : 'Develop.',
  apiUrl: 'http://localhost:4000',
  suffix:'gmail.com',
  firebase : {
    apiKey: "AIzaSyCxVrdfALWZhv7nwYeCLIxyE17sGrRBGRQ",
    authDomain: "boscooldev.firebaseapp.com",
    databaseURL: "https://boscooldev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "boscooldev",
    storageBucket: "boscooldev.appspot.com",
    messagingSenderId: "138884311348",
    appId: "1:138884311348:web:f7f1cdb52bdfa921015d61",
    measurementId: "G-7E535FFD06"
  }

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
