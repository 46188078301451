import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from "../../boilerplate/auth.service";
import * as CryptoJS from 'crypto-js';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ColleaguesService } from '../../colleagues/colleagues.service';
import { ParametersService } from '../parameters.service';
import { LocalUser } from '../../boilerplate/local-user';
import { Editor, Toolbar, Validators } from 'ngx-editor';
import { UrlService } from '../url.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-tronches',
  templateUrl: './tronches.component.html',
  styleUrls: ['./tronches.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TronchesComponent {
  @ViewChild('MailWhenDeletePhoto') photoModal: ElementRef;
  @ViewChild('MailWhenDeleteDescription') descriptionModal: ElementRef;
  colleague: any;
  moderatorEmailAddress:string;
  localUserData: LocalUser;
  editor: Editor;
  closeModal: string;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],

  ];


  formTronche = new FormGroup({
    editorContent: new FormControl('', Validators.required()),
  });

  editMode: boolean = false
  id: string;

  previousUrl: Observable<string> = this.urlService.previousUrl$;
  isLoading = false; // sending the post request async so it's in progress
  submitted = false; // hide the response message on multiple submits
  responseMessage: string; // the response message to show to the user
  enableRoleField: boolean;
  //colleague: any;


  constructor(
    private location: Location,
    public authservice: AuthService,
    private actRoute: ActivatedRoute,   // Activated route to get the current component's inforamation
    public colleaguesApi: ColleaguesService,
    public parametersApi: ParametersService,
    private urlService: UrlService,
    private http: HttpClient,
    private modalService: NgbModal,


  ) { // console.log(Date() ,'constructor')
  }



  ngOnInit(): void {
    // console.log(Date() ,'ngoninit')

    if (localStorage.getItem('userData') !== null) {
      this.localUserData = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('userData').trim(), 'AMortLeCovid').toString(CryptoJS.enc.Utf8));
      //console.log(Date() ,this.localUserData)

      this.editor = new Editor();


    }

    this.id = this.actRoute.snapshot.paramMap.get('id');  // Getting current component's id or information using ActivatedRoute service
    //  console.log(Date() ,id)


    this.colleaguesApi.GetColleague(this.id).valueChanges().subscribe(data => {
      //   console.log(Date() ,data)  // Using SetValue() method, It's a ReactiveForm's API to store intial value of reactive form 
      this.colleague = data
      if(this.colleague.role!="1,5"){
        this.enableRoleField=true;
      }
      else{
        this.enableRoleField=false;
      }

      this.formTronche.controls['editorContent'].setValue(this.colleague.autoDescriptive)
    })

    this.parametersApi.GetParameter().snapshotChanges().subscribe(data => {
      this.moderatorEmailAddress = data.payload.toJSON()['moderatorEmailAddress']

    })

    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      if (previousUrl == "/profile") {
        this.editMode = true;
      }
      else {
        this.editMode = false
      }
    });



  }

  get doc(): AbstractControl {
    return this.formTronche.get("editorContent");
  }

  onBack() {
    this.location.back();
  }

  Signal() {

    //console.log(this.colleague)
    this.formTronche.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Photo de profil inappropriée");
    formData.append("firstName", this.colleague.firstName);
    formData.append("lastName", this.colleague.lastName);
    formData.append("emailAddress", this.moderatorEmailAddress);
    //ilAddress+"?subject=Photo de profil inappropriée&body=. %0A " +this.localUserData.displayName


    let body =
      "Bonjour,\n" +
      "\n" +
      "La photo de profil de " + this.colleague.firstName + " " + this.colleague.lastName + " a été signalée par " + this.localUserData.displayName + "\n" +
      "\n" +
      `Si vous le désirez, vous pouvez donc vous connecter à BossCool et supprimer sa tronche.\n` +

      "\u000A" +

      "Votre équipe BossCool" + "\u000A" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "Le message a bien été envoyé au modérateur.";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.formTronche.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.formTronche.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }




  deletePicture() {
  
    
    if (Number(this.localUserData.role) < 3) return
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button

    const id = this.actRoute.snapshot.paramMap.get('id');
    this.triggerModal(this.photoModal);
    //console.log(id)
    this.colleaguesApi.UpdateCurrentColleaguePhotoURL(id, 'Einstein');
  
  }

  triggerModal(content) {
   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result
      .then((res) => {
        this.closeModal = `Closed with: ${res}`;
      }, (res) => {
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
      })
      .catch(function (error) {
        console.log(Date(), error.message)
      });
  }

  private getDismissReason(reason: any): string {
    //console.log(reason)
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteText() {
 
  
    if (Number(this.localUserData.role) < 3) return
    this.submitted = false; // show the response message
    this.isLoading = false; // re enable the submit button

    const id = this.actRoute.snapshot.paramMap.get('id');
    this.triggerModal(this.descriptionModal);
    //console.log(id)
    this.colleaguesApi.UpdateCurrentColleagueAutoDescriptive(id, "Pas d'auto-descriptif");
   
  }


  toggleAction() {
    if (this.editMode) {
      this.colleaguesApi.UpdateCurrentColleagueAutoDescriptive(this.localUserData.emailID, this.formTronche.controls['editorContent'].value)

      this.editMode = false
    }
    else {

      this.editMode = true

    }
  }

 mailPhoto() {
    //console.log(this.colleague)
    this.formTronche.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Photo de profil supprimée");
    formData.append("firstName", this.colleague.firstName);
    formData.append("lastName", this.colleague.lastName);
    formData.append("emailAddress", this.colleague.emailAddress);


    let body =
      "Bonjour,\n" +
      "\n" +
      "Le modérateur de BossCool vient d'effacer votre photo de profil car elle n'est pas adaptée.\n" +
      "\n" +
      `Veuillez en recréer une qui montre bien votre visage. \n` +
      "\u000A" +
      "Merci." + "\u000A" +
      "Votre équipe BossCool" + "\u000A" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "Le message a bien été envoyé au délinquant.";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.formTronche.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.formTronche.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }
  mailDescription() {
    //console.log(this.colleague)
    this.formTronche.disable(); // disable the form if it's valid to disable multiple submissions
    this.isLoading = true; // sending the post request async so it's in progress
    this.submitted = false; // hide the response message on multiple submits

    var formData: any = new FormData();
    formData.append("subject", "BossCool - Descriptif de profil supprimé");
    formData.append("firstName", this.colleague.firstName);
    formData.append("lastName", this.colleague.lastName);
    formData.append("emailAddress", this.colleague.emailAddress);


    let body =
      "Bonjour,\n" +
      "\n" +
      "Le modérateur de BossCool vient d'effacer votre descritpif de profil car il n'est pas adapté.\n" +
      "\n" +
      `Veuillez en recréer un plus correct. \n` +
      "\u000A" +
      "Merci." + "\u000A" +
      "Votre équipe BossCool" + "\u000A" +
      "\n" +
      "... ne malmenez pas trop Expresso l'escargot\n"

    formData.append("message", body);


    this.http.post("https://script.google.com/macros/s/AKfycbzpQpkA5zTCGf44hgd0Ts7yJApsWOOqlGqRcukc4Fuhx58W-xRC/exec", formData).subscribe(
      (response) => {
        // choose the response message
        if (response["result"] == "success") {
          this.responseMessage = "Le message a bien été envoyé au délinquant.";
        } else {
          this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        }
        this.formTronche.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        //console.log(response);
      },
      (error) => {
        this.responseMessage = "OOPs. Quelque chose ne va pas. Veuillez recommencer !";
        this.formTronche.enable(); // re enable the form after a success
        this.submitted = true; // show the response message
        this.isLoading = false; // re enable the submit button
        console.log(error);
      }
    );
  }

  onBlock() {

    const id = this.actRoute.snapshot.paramMap.get('id');
    this.colleaguesApi.UpdateCurrentColleagueRole(id, "1,5");

  }


  onNotBlock() {
    const id = this.actRoute.snapshot.paramMap.get('id');
    this.colleaguesApi.UpdateCurrentColleagueRole(id, "2");
  }

}
