<header class="page-header" id="header" >
  <app-header></app-header>
</header>
<div class=" border-bottom" style="text-align: center; margin-top: 100px; margin-bottom: 15px;">
    <h1 style="color:white;">Inviter un collègue</h1>
  </div>
    


    
      <div class="wrapper">
        <div class="cards_wrap">
          <div class="card_item" style="margin-bottom: 20px;">
            <div class="card_inner">
              <div style="text-align: right;margin-bottom: -25px">
                <button ngbTooltip="Aide" class="btn" (click)="triggerModal(modalDataInviteColleagues)">
                  <span class="icon-iconInfo" style="font-size: 1.4em;"></span>
                </button>
              </div>
              <div class="card_top center">
                
              </div>
              <div class="card_bottom">
        
                <form [formGroup]="inviteColleagueForm" (ngSubmit)="submitColleagueData()" novalidate>
                <div class="card_info">         
    
              <label>Prénom</label>
              <input type="text" formControlName="firstName" style="text-transform:capitalize;" class="formControl left" required>
              <p *ngIf="firstName.touched && firstName.invalid" class="error"><sup>*</sup>Veuillez entrer un prénom</p>
              <p *ngIf="firstName.errors?.pattern" class="error"><sup>*</sup>Chaque première lettre (y compris après un espace ou un trait d'union pour les prénoms
              composés) doit être en majuscule, toutes les autres en minuscules.</p>

              <label>Nom</label>
              <input type="text" style="text-transform: uppercase;" formControlName="lastName" class="formControl left" required>
              <p *ngIf="lastName.touched && lastName.invalid" class="error"><sup>*</sup>Veuillez entrer un nom de famille</p>

              <label>Email</label>
              <input type="text" formControlName="emailAddress" class="formControl left" required>
              <p *ngIf="emailAddress.touched && emailAddress.invalid" class="error"><sup>*</sup>Veuillez entrer une adresse email</p>
              <p *ngIf="emailAddress.errors?.pattern" class="error"><sup>*</sup>Voyons, ceci n'est pas une adresse email !</p>
             

              <input type="text" formControlName="magicProtection" class="formControl left"  hidden/>
              <div *ngIf="(!submitted)&&(isLoading)" class="modal-body" style=" text-align:center ; font-size: 13pt;">
                Envoi du message... Merci de patienter.
                <br>
                <img src="assets/preloader.svg" alt="" style="height: 100px ;width:100px">
            
              </div>
              <div [ngClass]="!submitted? 'hidden' : 'visible'" style=" text-align:center ;">
                <span>{{responseMessage}}</span>
              </div>
              
    
                </div>
                <div class="card_creator">
                  <button type="button" class="btn btn-danger " style="border-radius:10px;padding: 10px;margin-top: 10px;margin-right: 30px;margin-bottom: 10px;" (click)="goBack()">
                    <span *ngIf="submitted" >Retour </span>
                    <span *ngIf="!submitted" >Annuler </span>
                    <span *ngIf="!submitted" class="icon-iconEffacer"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
                    <span *ngIf="submitted" class="icon-iconRetour"style="font-size: 1.3em;"><span class="path2"></span></span>
                    
                  </button>
                  <button *ngIf="!submitted" type="submit" class="btn btn-primary" style="border-radius:10px;padding: 10px;margin-top: 10px; margin-bottom: 10px;" [disabled]="!inviteColleagueForm.valid">
                    Valider
                    <span class="icon-iconValider"style="font-size: 1.3em;"><span class="path1"></span><span class="path2"></span></span>
                  </button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
  
    

        <ng-template #modalDataInviteColleagues let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Pas de panique !</h4>
            <button type="button" class="btn close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="font-size: 13pt;margin-bottom: -20px;">
        
            C'est ici que vous pouvez demander d'inviter un collègue qui ne possède pas une adresse email de votre établissement.
            Après avoir cliqué sur "Valider", votre logiciel d'email s'ouvrira et cela vous permettra d'envoyer un mail
             au modérateur de votre établissement afin de finaliser votre demande d'invitation. 
             <br> 
            De la même façon que vous, ce collègue devra s'enregistrer dans BossCool pour pouvoir s'y connecter et accepter
             les travaux que vous avez mentionnés pour lui.
             <br> 
             <span style="text-decoration: underline;"> Remarque</span> :
             <br> 
            Il ne vous est pas permis d'inviter un collègue qui possède une adresse email de votre établissement.
            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Fermer
              <span class="icon-iconEffacer" style="font-size: 1.3em;"><span class="path1"></span><span
                  class="path2"></span></span>
            </button>
          </div>
        </ng-template>

        